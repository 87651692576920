import { Box, Button, Icon, Table, Tbody, Td, Text,  Tr } from "@chakra-ui/react";
import React from "react";
import { useUpdate } from "react-admin";
import { GiDonerKebab } from "react-icons/gi"
import { MdCancel } from "react-icons/md"
import { RiErrorWarningLine } from "react-icons/ri"

const getPaypmentStatus = ({ payment }) => {

    const statusArray = ["waiting", "refund", "completed"]

    let currentStatusIndex =  undefined


    for (const p of payment) {

        const index =  statusArray.indexOf(p.status)

        if ( currentStatusIndex == null || index < currentStatusIndex)
        {
            currentStatusIndex = index
        }
    }

    return  currentStatusIndex ? statusArray[currentStatusIndex] : statusArray[0]
}

export const MarkAsPayed = ({ record }) => {

    const [update, { loading, error }] = useUpdate();
    const status = getPaypmentStatus(record)


    if (status === "completed")
    {
        return <Button float="right" variant="secondary" disabled={true}>Rückerstattung</Button>;
    }
    

    const payment = []

    for (const p of record.payment) {
        
        payment.push({...p,
                        status: "completed"})
    }

    const diff = { payment };
  
    const handleClick = () => {
        update('order', record.id, diff, record)
    }
    if (error) { return <p>ERROR</p>; }
    return <Button variant="contained" disabled={loading} onClick={handleClick} color="primary">Als Bezahlt markieren</Button>;

};


export const PaymentHeader = (props) => { 
    const status = getPaypmentStatus(props.record)

    let text, clz = undefined
    let icon = RiErrorWarningLine;
    switch (status) {
        case 'refund':
            clz = "red.600"
            text = "Zurückerstattet"
            icon = MdCancel

            break;
        case 'completed':
            clz = "green.600"
            text = "Zahlung abgeschlossen"
            icon = GiDonerKebab
            break;
        case 'waiting':
        default:
            clz = "orange.400"
            text = "Ausstehend"
    }


    return (
        <Box display="flex" fontSize="3xl" alignItems="center">
            <Icon m="2" color={clz} as={icon} />
            <Text fontSize="2xl">{text}</Text>
        </Box>
    )
}
;

export default function DisplayPayment(props) {
    const { record } = props
    return (
        <Box>
        <Table variant="striped" colorScheme="gray">
            <Tbody>
                <Tr >
                    <Td >Grundpreis</Td>
                    <Td ></Td>
                    <Td isNumeric fontSize="lg" fontWeight="bolder">{(
                        record.value.total.tax + record.value.total.amount
                    ).toLocaleString("de-DE", {
                        style: 'currency',
                        currency: 'EUR',
                    })}</Td>
                </Tr>
                {record?.discounts.length > 0 &&
                    <Tr borderBottom="1.1px solid black" >

                        <Td >Rabatt</Td>
                        <Td ></Td>
                        <Td >
                        </Td>
                    </Tr>
                }
                {record?.discounts.length > 0 && record?.positions?.map((el) =>
                    <Tr >
                        {el.discounts.map((dis) => {
                            let rabatt = record.discounts.find((rab) => rab._id === dis.discount)
                            return (
                                <>
                                    <Td textAlign="end">{rabatt.code}</Td>
                                    <Td textAlign="end">{rabatt.message}</Td>
                                    <Td isNumeric>{"- " + (
                                        dis.value.tax + dis.value.amount
                                    ).toLocaleString("de-DE", {
                                        style: 'currency',
                                        currency: 'EUR',
                                    })}</Td></>

                            )
                        })}
                    </Tr>
                )}
                <Tr borderTop={record?.discounts.length > 0 ? "1.1px solid black" : undefined} >
                    <Td>Versand</Td>
                    <Td></Td>
                    <Td isNumeric>{(
                        record.value.shipping.tax + record.value.shipping.amount
                    ).toLocaleString(undefined, {
                        style: 'currency',
                        currency: 'EUR',
                    })}</Td>
                </Tr>
                <Tr >
                    <Td>Steuer</Td>
                    <Td>MwSt 19% (inklusive)</Td>
                    <Td isNumeric>{(
                        record.value.shipping.tax + record.value.total.tax
                    ).toLocaleString(undefined, {
                        style: 'currency',
                        currency: 'EUR',
                    })}</Td>
                </Tr>
                <Tr fontSize="lg" fontWeight="600">
                    <Td>Gesamtsumme</Td>
                    <Td></Td>
                    <Td isNumeric>{(
                        record.value.shipping.tax + record.value.total.tax + record.value.shipping.amount + record.value.total.amount
                    ).toLocaleString(undefined, {
                        style: 'currency',
                        currency: 'EUR',
                    })}</Td>
                </Tr>
                <Tr >
                    <Td>Zahlungsart</Td>
                    <Td>{record.payment[0].method}</Td>
                    <Td >{record.payment[0].status}</Td>
                </Tr>
                <Tr >
                    <Td colSpan={3}>
                        <MarkAsPayed {...props} />
                    </Td>
                </Tr>
            </Tbody>
        </Table>
        </Box>
    )
}