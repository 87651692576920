import {
    Edit,
    TextInput,
    FormWithRedirect,
    required,
} from 'react-admin';

import { Grid } from '@material-ui/core';
import EditIcon from '@material-ui/icons/Edit';
import FileCopyIcon from '@material-ui/icons/FileCopy';
import Button from '@material-ui/core/Button';
import {
    SaveButton,
    useUpdate,
    useNotify
} from 'react-admin';

import React, { useState } from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import IconCancel from '@material-ui/icons/Cancel';
import { Variant } from '../../util/style'
import { Box, Text, IconButton } from '@chakra-ui/react';

function EditContact(props) {
    let { record, onChange, type, name, display } = props
    const [showDialog, setShowDialog] = useState(false);
    const [update, { loading }] = useUpdate();
    const notify = useNotify();

    const handleClick = () => {
        setShowDialog(true);
    };

    const handleCloseClick = () => {
        setShowDialog(false);
    };

    const handleCopyClick = () => {
        const text = `${record?.billingAdress.email}`

        copyTextToClipboard(text)
            .then(() => {
                // If successful, update the isCopied state value
                notify("Email Adresse in Zwischenablage übernommen", 'success');
            })
            .catch((err) => {
                console.log(err);
            });
    }

    async function copyTextToClipboard(text) {
        if ('clipboard' in navigator) {
            return await navigator.clipboard.writeText(text);
        } else {
            return document.execCommand('copy', true, text);
        }
    }

    const handleSubmit = async values => {
        update("order", record.id, { shippingAdress: values.shippingAdress }, record,
            {
                onSuccess: ({ data }) => {
                    setShowDialog(false);
                    onChange();
                },
                onFailure: ({ error }) => {
                    notify(error.message, 'error');
                }
            }
        );
    };

    return (
        <>
            <Box w="100%" border="1px solid lightgrey" backgroundColor="white" shadow="md" p="3">
                <Box display="flex" justifyContent="space-between">
                    <Text fontSize="2xl" fontWeight="semibold">{name}</Text>
                    {!display && <Box>

                        <IconButton variant="ghost" onClick={handleCopyClick}>
                            <FileCopyIcon />
                        </IconButton>
                        <IconButton variant="ghost" onClick={handleClick}>
                            <EditIcon />
                        </IconButton>

                    </Box>}
                </Box>
                <Box>
                    <Text>
                        {record?.billingAdress.email ? record?.billingAdress.email : "Keine Email "}
                    </Text>
                    <Text>Keine Telefonnummer</Text>
                </Box>
            </Box>
            <Dialog
                fullWidth
                open={showDialog}
                onClose={handleCloseClick}
                aria-label={`${name} ändern`}
            >
                <DialogTitle>{`${name} ändern`}</DialogTitle>
                <Edit  {...props} id={props.record.id} component="div">
                    <FormWithRedirect
                        resource="order"
                        save={handleSubmit}
                        render={({
                            handleSubmitWithRedirect,
                            pristine,
                            saving

                        }) => (
                            <>
                                <DialogContent>
                                    <Grid container xs={12} spacing={1}>
                                        <Grid item xs={6}>

                                            <TextInput
                                                source={`billingAdress.email`}
                                                validate={required()}
                                                label="Email"
                                                fullWidth
                                                variant={Variant}
                                                size="small"
                                            />
                                        </Grid>
                                        <Grid item xs={6}>
                                            <TextInput
                                                source={`${type}.telefone`}
                                                label="Telefonnummer"
                                                fullWidth
                                                disabled
                                                variant={Variant}
                                                size="small"
                                            />
                                        </Grid>
                                    </Grid>

                                </DialogContent>
                                <DialogActions>
                                    <Button
                                        label="ra.action.cancel"
                                        onClick={handleCloseClick}
                                        disabled={loading}
                                    >
                                        <IconCancel />
                                    </Button>
                                    <SaveButton
                                        handleSubmitWithRedirect={
                                            handleSubmitWithRedirect
                                        }
                                        pristine={pristine}
                                        saving={saving}
                                        disabled={loading}
                                    />
                                </DialogActions>
                            </>
                        )}
                    />
                </Edit>
            </Dialog>
        </>
    );
}

export default EditContact;