import { Card, CardContent, Chip, Typography } from '@material-ui/core'
import { ArrayField, ChipField, Datagrid, DateField, FilterList, FilterListItem, List, ListBase, Pagination, ReferenceField, SingleFieldList, TextField } from 'react-admin'
import { MdCategory } from 'react-icons/md'

export const TimelineList = (props) => {
    let datum = new Date(Date.now())

    const QuickFilter = ({ label }) => {
        return <Chip label={label} />
    }
    
    return (
        <List perPage="25" filterDefaultValues={{category: "shipment"} } sort={{ field: 'tsPlanned', order: 'DESC' }} {...props}  aside={<TimelineFilterSidebar/>}>
            <Datagrid>
                <ReferenceField label="Bestellung" source="parent.id" reference="order">
                    <TextField source="id" />
                </ReferenceField>
                <ReferenceField label="Produkt" source="parent.id" reference="order" sortable="false">
                        <ArrayField source="positions">
                            <SingleFieldList>
                            <ReferenceField source="product" reference='product'>
                                <TextField source="name" />
                            </ReferenceField>
                            </SingleFieldList>
                        </ArrayField>
                    </ReferenceField>
                <ChipField source="category" />
                <ChipField source="status" />
                <DateField source="tsPlanned" options={{ year: 'numeric', month: '2-digit', day: '2-digit' }} label="Geplante Ausführung" />
                <TextField source="message" label="Beschreibung" />
                
            </Datagrid>
        </List>
    )
}

const TimelineFilterSidebar = () => {
    return (
        <Card sx={{ order: -1, mr: 2, mt: 9, width: 200 }}>
            <CardContent>
                <FilterList label="Kategorie" icon={<MdCategory />} >
                    <FilterListItem label="Versand" value={{ category: 'shipment' }} />
                    <FilterListItem label="Rücksendung" value={{ category: 'returnShipment' }} />
                    <FilterListItem label="E-Mail Versand" value={{ category: 'mail' }} />
                    <FilterListItem label="E-Mail Rücksendung" value={{ category: 'NOTIFICATION_SHIPMENT_RETURN' }} />
                </FilterList>
                <FilterList label="Status" icon={<MdCategory />} >
                    <FilterListItem label="Offen" value={{ status: 'open' }} />
                    <FilterListItem label="Abgeschlossen" value={{ status: 'completed' }} />
                    <FilterListItem label="Fehler" value={{ status: 'error' }} />
                    <FilterListItem label="Suspended" value={{ status: 'suspended' }} />
                </FilterList>
            </CardContent>
        </Card>
    )
}
