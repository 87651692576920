import { Box, NumberDecrementStepper, NumberIncrementStepper, NumberInputField, NumberInputStepper, Table, Tbody, Td, Text, Thead, Tr, NumberInput, Flex } from '@chakra-ui/react'
import { useField, useFormik, useFormikContext } from 'formik'
import { useEffect, useState } from 'react'
import { Link, useQueryWithStore } from 'react-admin'

export default function SelectProductOptions(props) {
    const { setFieldValue } = useFormikContext()
    const { record } = props

    const [selectedProducts, setSelectedProducts] = useState([])

    const { loaded, data: products } = useQueryWithStore(
        {
            type: 'getMany',
            resource: 'product',
            payload: {
                ids: record ? record.positions.map((item) => item.product) : [],
            },
        },
        {},
        (state) => {
            const productIds = record ? record.positions.map((item) => item.product) : []

            return productIds
                .map((productId) => state.admin.resources.product.data[productId])
                .filter((r) => typeof r !== 'undefined')
                .reduce((prev, next) => {
                    prev[next.id] = next
                    return prev
                }, {})
        }
    )

    const { loaded: optionLoaded, data: options } = useQueryWithStore(
        {
            type: 'getMany',
            resource: 'option',
            payload: {
                ids: record ? record.positions.map((item) => item.options.map((option) => option.option)).flat() : [],
            },
        },
        {},
        (state) => {
            const ids = record ? record.positions.map((item) => item.options.map((option) => option.option)).flat() : []

            return ids
                .map((id) => {
                    return state.admin.resources.option.data[id]
                })
                .filter((r) => typeof r !== 'undefined')
                .reduce((prev, next) => {
                    prev[next.id] = next
                    return prev
                }, {})
        }
    )

    useEffect(() => {
        if (loaded && optionLoaded && selectedProducts.length === 0) {
            for (let item of record.positions) {
                let found = selectedProducts.find((el) => el.product === item.product)
                let neu = []
                if (!found) {
                    neu.push({ product: item.product, anzahl: 1 })
                }
                setSelectedProducts(neu)
                let form = []
                neu.forEach((el) => {
                    if (el.anzahl > 0) {
                        let pos = record.positions.find((value)=>(value.product === el.product))
                        if(pos){
                            form.push(pos._id)
                        }
                        
                    }
                })
                setFieldValue(props.name, form)
            }
        }
    }, [loaded, optionLoaded])
    if (!loaded || !optionLoaded || !props.record) return null

    return (
        <Box border="1px solid lightgrey" mb="1">
            <Table variant="striped" colorScheme="gray" w="100%">
                <Thead>
                    <Tr>
                        <Td>Produkt</Td>
                        <Td></Td>
                        <Td>Anzahl</Td>
                    </Tr>
                </Thead>
                <Tbody>
                    {record?.positions.map((item, index) => {
                        return (
                            products[item.product] && (
                                <>
                                    <Tr key={`${item.product}_${index}`}>
                                        <Td>
                                            <Link to={`/product/${item.product}`}>{products[item.product].name}</Link>
                                        </Td>
                                        <Td></Td>
                                        <Td colSpan={2} span>
                                            <Flex flexDir="row" alignItems="center" justifyContent="center">
                                                <NumberInput
                                                    onChange={(valueString) => {
                                                        let found = selectedProducts.findIndex((el) => el.product === item.product)
                                                        if (found >= 0) {
                                                            let neu = [...selectedProducts]
                                                            neu[found]['anzahl'] = valueString
                                                            setSelectedProducts(neu)
                                                            let form = []
                                                            neu.forEach((el) => {
                                                                if (el.anzahl > 0) {
                                                                    let pos = record.positions.find((value)=>(value.product === el.product))
                                                                    console.log("§pos",pos)
                                                                    if(pos){

                                                                        form.push(pos._id)
                                                                    }
                                                                    
                                                                }
                                                            })
                                                            setFieldValue(props.name, form)
                                                        }
                                                    }}
                                                    value={
                                                        selectedProducts.find((el) => el.product === item.product)
                                                            ? selectedProducts.find((el) => el.product === item.product).anzahl
                                                            : 0
                                                    }
                                                    size="sm"
                                                    w="4em"
                                                    max={1}
                                                    min={0}
                                                >
                                                    <NumberInputField />
                                                    <NumberInputStepper>
                                                        <NumberIncrementStepper />
                                                        <NumberDecrementStepper />
                                                    </NumberInputStepper>
                                                </NumberInput>
                                                <Box width="2em" fontSize="xl" pl="0.5em">
                                                    {' / 1'}
                                                </Box>
                                            </Flex>
                                        </Td>
                                    </Tr>
                                    {item.options.map(
                                        (option, index) =>
                                            options[option.option] && (
                                                <Tr key={`${option._id}_${index}`}>
                                                    <Td></Td>
                                                    <Td>
                                                        <Link to={`/option/${option.option}`}>{options[option.option].name}</Link>
                                                    </Td>
                                                    <Td></Td>
                                                </Tr>
                                            )
                                    )}
                                </>
                            )
                        )
                    })}
                </Tbody>
            </Table>
        </Box>
    )
}
