import { extendTheme } from "@chakra-ui/react"
const theme = extendTheme({
    colors:{
        secondary:"#2196f3",
        primary:"#3f51b5"
    },
    config:{
        initialColorMode: 'light',
        useSystemColorMode: false,
    },
    components:{
        Button:{
            variants:{
                primary:{
                    backgroundColor: "primary",
                    _hover:{},
                    _focus:{},
                    color:"white"
                },
                secondary:{
                    backgroundColor: "secondary",
                    _hover:{},
                    _focus:{},
                    color:"white"
                }
            }
        },
        ModalContent:{
            baseStyle:{
                zIndex: 10
            }
        },
        DialogContainer:{
            baseStyle:{
                zIndex:10
            }
        },
        Dialog:{
            baseStyle:{
                zIndex:10
            }
        }

    }
})

export default theme