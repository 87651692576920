import { Datagrid, List, ReferenceField, TextField } from "react-admin";

export const DiscountcodeList = props => (
    <List {...props}>
        <Datagrid rowClick="edit">
            <TextField source="id" />
            <TextField source="code" />
            <ReferenceField source="rule" reference="discountrule" label="Regel Id">
                <TextField source="id" />
            </ReferenceField>
            <TextField source="usage.count" label="Verwendungen"/>
            <ReferenceField source="rule" reference="discountrule" label="Regel Titel">
                <TextField source="title" />
            </ReferenceField>

        </Datagrid>
    </List>
);