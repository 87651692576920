import {
  Create,
  SimpleForm,
  TextInput,
  DateInput,
  NumberInput,
  SelectInput,
  AutocompleteInput
} from "react-admin";


import { Variant } from '../../util/style'

export const OptionCreate = (props: any) => (
  <Create {...props}>
    <SimpleForm variant={Variant}>
      <TextInput source="name" />
      <TextInput source="hint"  />
      <TextInput source="description" multiline={true} />
      <AutocompleteInput label="Art" source="type" choices={[
                { id: 'duration', name: 'Dauer' },
                { id: 'addon', name: 'Addon' },
            ]} variant={Variant} />
      <TextInput label="Dauer" source="additionalAttributes.duration" />
      
      <NumberInput
                  
                  source="price.amount"
                  label="Preis"
                />
       <SelectInput
                  
                  source="price.currency"
                  label="Währung"
                  choices={[{ id: "€", name: "€" }]}
                  defaultValue="€"
                />

        <NumberInput
              
                  source="price.offer.amount"
                  label="Angebots Preis"
                />
         <SelectInput
               
                  source="price.offer.currency"
                  label="Währung"
                  choices={[{ id: "€", name: "€" }]}
                  defaultValue="€"
                />
          <DateInput label="Angebots Start" source="price.offer.tsStart"  />
          <DateInput label="Angebots Ende" source="price.offer.tsEnd" />
     
    </SimpleForm>
  </Create>
);
