import { Box, Divider, Flex, Table, Tbody, Td, Thead, Tr, Button } from '@chakra-ui/react'
import dayjs from 'dayjs'
import { useEffect } from 'react'
import { Link, useQueryWithStore } from 'react-admin'
import { DefaultApi } from '../../api/private'
import { ListingApi } from '../../api/public'

const FullfillmentDisplay = (props) => {
    let { fullfillment } = props
    let { record } = props
    
    const fullfilledIds = record.positions?.map((val)=>{
        if(fullfillment.positions.includes(val._id)){
            const obj = {
                product: val.product,
                options: []
            }
            val.options?.forEach((opt)=>{
                obj.options.push(opt.option)
            })
            return obj
        }
        
    })
    const productIds = fullfilledIds.map((val)=>{
        return val.product
    })



    const { loaded, data: products } = useQueryWithStore(
        {
            type: 'getMany',
            resource: 'product',
            payload: {
                ids: productIds,
            },
        },
        {},
        (state) => {
            return productIds
                .map((productId) => state.admin.resources.product.data[productId])
                .filter((r) => typeof r !== 'undefined')
                .reduce((prev, next) => {
                    prev[next.id] = next
                    return prev
                }, {})
        }
    )

    const { loaded: optionLoaded, data: options } = useQueryWithStore({
        type: 'getMany',
        resource: 'option',
        payload: {
            ids: record ? record.positions.map((item) => item.options.map((option) => option.option)).flat() : [],
        },
    })
    if (!loaded || !optionLoaded || !props.record || !fullfillment) return null
    console.log("rec",record)
    console.log("full",fullfillment)

    let currentPositions = record.positions.filter(_=> fullfillment.positions.includes(_._id));
    console.log("current",currentPositions)
    return (
        <Box w={['100%', '100%', '70%', '60%']}>
            <Flex w="100%">
                <Box w={['50%', '50%', '50%', '30%']}>Versanddatum:</Box> <Box>{dayjs(currentPositions[0].shipDate).format('DD.MM.YYYY')}</Box>
            </Flex>
            <Flex w="100%">
                <Box w={['50%', '50%', '50%', '30%']}>Zugestellt:</Box> <Box>{dayjs(fullfillment.shipment.tsDelivered).format('DD.MM.YYYY')}</Box>
            </Flex>
            <Box mt="2" opacity="0.7">
                Positionen:
            </Box>
            <Box mb="1">
                <Table variant="striped" colorScheme="gray">
                    <Tbody>
                        {fullfilledIds.map((item, index) => {
                            return (
                                products[item.product] && (
                                    <>
                                        <Tr key={`${item.product}_${index}`}>
                                            <Td>
                                                <Link to={`/product/${item.product}`}>{products[item.product].name}</Link>
                                            </Td>
                                            <Td></Td>
                                            <Td fontWeight="bold"></Td>
                                        </Tr>
                                        {options.map((opt) => (
                                            <Tr>
                                                <Td></Td>
                                                <Td>{opt.name}</Td>
                                                <Td></Td>
                                            </Tr>
                                        ))}
                                    </>
                                )
                            )
                        })}
                    </Tbody>
                </Table>
            </Box>
            <Flex w="100%" justifyContent="end"><Button colorScheme="yellow" onClick={()=>{
                window.open(process.env.REACT_APP_API_URL+"/fullfillment/"+fullfillment.id+"/shipmentLabel","_blank").focus()
            }} >Versandmarke</Button></Flex>
            <Divider mb="2" mt="2" />
        </Box>
    )
}

export default FullfillmentDisplay
