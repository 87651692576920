import debounce from "lodash/debounce";
import React, {
  ComponentProps,
  useState,
} from "react";
import Editor from "react-simple-code-editor";
import { highlight, languages } from "prismjs";
import "prismjs/components/prism-jsx";
import { useInput, FieldTitle } from "ra-core";
import { InputHelperText } from "ra-ui-materialui";
import {
  FormHelperText,
  FormControl,
  InputLabel,
  PropTypes as MuiPropTypes,
} from "@material-ui/core";
import PropTypes from "prop-types";
import "prismjs/themes/prism.css";
export interface RichCodeInputProps {
  label?: string | false;
  source: string;
  helperText?: ComponentProps<typeof InputHelperText>["helperText"];
  record?: Record<any, any>;
  resource?: string;
  variant?: string;
  margin?: MuiPropTypes.Margin;
  [key: string]: any;
}

const RichCodeInput = (props: RichCodeInputProps) => {
  // eslint-disable-next-line react-hooks/exhaustive-deps

  const {
    fullWidth = true,
    helperText,
    label,
    source,
    resource,
    variant,
    margin = "dense",
    classes: classesOverride,
    ...rest
  } = props;

  const {
    id,
    isRequired,
    input: { value, onChange },
    meta: { touched, error },
  } = useInput({ source, ...rest });
  const [code, setCode] = useState(value);
  const onTextChange = debounce((value: string) => {
    onChange(value);
  }, 500);

  return (
    <FormControl
      error={!!(touched && error)}
      fullWidth={fullWidth}
      className="ra-rich-code-input"
      margin={margin}
    >
      <InputLabel shrink htmlFor={id}>
        <FieldTitle
          label={label}
          source={source}
          resource={resource}
          isRequired={isRequired}
        />
      </InputLabel>

      <Editor
        value={code}
        onValueChange={(code) => {
          setCode(code);
          onTextChange(code);
        }}
        highlight={(code) => highlight(code, languages.html, "html")}
        padding={25}
        style={{
          fontFamily: '"Fira code", "Fira Mono", monospace',
          fontSize: 14,
        }}
      />
      <FormHelperText
        error={!!error}
        className={!!error ? "ra-rich-text-input-error" : ""}
      >
        <InputHelperText
          error={error}
          helperText={helperText}
          touched={touched == null ? false : touched}
        />
      </FormHelperText>
    </FormControl>
  );
};

RichCodeInput.propTypes = {
  // @ts-ignore
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  options: PropTypes.object,
  source: PropTypes.string,
  fullWidth: PropTypes.bool,
  configureQuill: PropTypes.func,
};

export default RichCodeInput;
