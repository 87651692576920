import {
	List,
	Datagrid,
	TextField,
	DateField,
	NumberField,
    EmailField,
  } from "react-admin";

export const CustomerList = props => (
    <List {...props} perPage={25}>
        <Datagrid rowClick="edit">
            <TextField source="shippingAdress.firstName" label="Empfänger Vorname"/>
            <TextField source="shippingAdress.lastName" label="Empfänger Nachname"/>
            <EmailField source="email" label="E-Mail"/>
            <DateField source="tsLastOrder" label="Letzte Bestellung" locales="de-DE" options={{ year: '2-digit', month: '2-digit', day: 'numeric', hour: "2-digit", minute:"2-digit" }}/>
            <NumberField source="orderCount" label="Anzahl Bestellungen" />
            <NumberField source="orderValueTotal" label="Preis" options={{ style: 'currency', currency: 'EUR' }} locales="de-DE"/>
            <TextField source="status" label="Status" />
            <DateField source="createdAt" label="Erstellt" locales="de-DE" options={{ year: '2-digit', month: '2-digit', day: 'numeric', hour: "2-digit", minute:"2-digit" }}/>
        </Datagrid>
    </List>
);