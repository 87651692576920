import lodashMemoize from 'lodash/memoize';

const memoize = (fn) =>
    lodashMemoize(fn, (...args) => JSON.stringify(args));

const NoMultibleDefaultOptions = memoize((values)=>{
	let foundDefault = false;
	if(values == null){
		return undefined
	}
	for(let val of values){
		if(val && val.isDefault && val.option.type === "duration"){
			if(foundDefault){
				return "Es wurden zwei Zeit-Optionen als Default gesetzt"
			}
			foundDefault = true
		}
	}
	return undefined
})




export {
	NoMultibleDefaultOptions
}