import {
	List,
	Datagrid,
	TextField,
	NumberField,
	ChipField,
	FunctionField,
	ReferenceField
  } from "react-admin";
 

const InventoryList = (props) => (
    <List {...props} perPage={25}>
        <Datagrid rowClick="edit">
            <TextField source="id" />
            <ReferenceField source="product" reference="product">
                <TextField source="name"/>
             </ReferenceField>
             <NumberField source="count" />
             <ChipField source="condition" />
             <ChipField source="completeness" />
             <ChipField source="status" />
             <FunctionField label="Verfügbar" render={record => `${record.available.length}`} />
             <FunctionField label="Gebucht" render={record => `${record.booked.length}`} />
             <FunctionField label="Reserviert" render={record => `${record.reserved.length}`} />       
        </Datagrid>
    </List>
);

export default InventoryList;