import { List,Datagrid,TextField,ChipField, FunctionField,EditButton} from "react-admin";


const totalRender = (entry:any) => {

    if (!entry.price || !entry.price.amount) {
      return `Nicht definiert`;
    }

    if (entry.price.offer)
    {
        return `<strike>${entry.price.amount} ${entry.price.currency}</strike> ${entry.price.offer.amount} ${entry.price.offer.currency}`
    }


    return `${entry.price.amount} ${entry.price.currency}`;
  }
  

export const OptionList =( props:any) => (
    <List {...props} perPage={25}>
        <Datagrid rowClick="edit">
            <TextField source="name" />
            <ChipField label="Art" source="type" />


            
            <TextField label="Dauer" source="additionalAttributes.duration" />
            <FunctionField label="Preis" src="price" render={totalRender} />
            <EditButton />
        </Datagrid>
    </List>
);