import {
	Edit,
	SimpleForm,
	TextInput,
	DateInput,
  } from "react-admin";
  
export const BookingEdit = props => (
    <Edit {...props}>
        <SimpleForm>
            <TextInput source="type" />
            <TextInput source="status" />
            <TextInput source="product" />
            <TextInput source="inventory" />
            <DateInput source="tsStart" />
            <DateInput source="tsEnd" />
            <DateInput source="milestone.undefined" />
            <DateInput source="updatedAt" />
            <DateInput source="createdAt" />
            <TextInput source="id" />
        </SimpleForm>
    </Edit>
);