import {
    FormControl,
    FormErrorMessage,
    FormLabel,
    Input,
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalBody,
    ModalCloseButton,
    Box,
    Button,
    Center,
    Icon,
    Text,
    Flex,
    Spinner,
} from '@chakra-ui/react'
import { ImCheckboxChecked } from 'react-icons/im'
import { MdCancel } from 'react-icons/md'
import { RiErrorWarningLine } from 'react-icons/ri'
import { Field, Form, useFormik, FormikProvider } from 'formik'
import { useState } from 'react'
import { useMutation, useNotify } from 'react-admin'
import * as yup from 'yup'
import EditAdress from '../EditAdress/EditAdress'
import EditContact from '../EditContact/EditContact'
import SelectProductPositons from '../SelectProductPositions/SelectProductPositions'
import { FullfillmentApi } from '../../api/private'
import dayjs from 'dayjs'
const validationSchema = yup.object({
    weight: yup
        .number()
        .transform((_value, originalValue) => Number(originalValue.replace(/,/, '.')))
        .required('Das Gewicht darf nicht leer sein')
        .test('maxDigitsAfterDecimal', 'Das Gewicht muss voranden sein und darf nicht mehr als 2 Nachkommastellen haben', (number) => /^\d+([,.]\d{1,2})?$/.test(number)),
        
    positions: yup.array().test({
        message: 'Mindestens 1 Position auswählen',
        test: (arr) => arr.length > 0,
    }),
    shipmentDate: yup
        .date()
        .required()
        .test('tag gleich größer Heute', 'Das Datum darf nicht in der Vergangenheit liegen', (day) => dayjs(new Date().setDate(new Date().getDate() - 1)).diff(dayjs(day)) < 0),
})

export const FulfillmentDialog = (props) => {
    const [open, setOpen] = useState(false)
    const { status } = props.record
    const notify = useNotify()
    const handleClose = () => {
        setOpen(false)
    }
    const [loading, setLoading] = useState(false);
    const formik = useFormik({
        validationSchema: validationSchema,
        initialValues: {
            weight: undefined,
            positions: [],
            shipmentDate: new Date().toISOString().substring(0, 10),
        },
        onSubmit: async (values) => {
            let api = new FullfillmentApi({ basePath: process.env.REACT_APP_API_URL })
            try {
                setLoading(true)
                await api.postFullfillment(props.record.id, {
                    positions: values.positions,
                    shipment: {
                        fullfiller: props.record.value.shipping.fulfiller ? props.record.value.shipping.fulfiller === "dhl" ? "DHL": props.record.value.shipping.fulfiller : "PickUp",
                        weight: Number(values.weight.replace(",", ".")),
                        shipmentDate: values.shipmentDate,
                    },
                })
                
                notify('Fullfillment gespeichert', 'success')
                handleClose()
                if (props.onSaveCallback) {
                    props.onSaveCallback()
                }
            } catch (e) {
                notify(e + '', 'error')
            }
            setLoading(false)
            
        },
    })
    let showButton = true
    switch (status) {
        case 'pending':
        case 'processing':
        case 'onHold':
            showButton = true
            break
        default:
            showButton = false
            break
    }
    return (
        <>
            {showButton ? (
                <Button onClick={() => setOpen(true)} variant="secondary">
                    Als bearbeitet markieren
                </Button>
            ) : (
                ''
            )}

            <Modal isOpen={open} onClose={handleClose} size={'6xl'}>
                <ModalOverlay />
                <ModalContent zIndex="1000">
                    <ModalHeader>Fulfillment</ModalHeader>
                    <ModalCloseButton />
                    <ModalBody>
                        <FormikProvider value={formik}>
                            <Box display="flex">
                                <Field name="positions">
                                    {({ form }) => {
                                        return (
                                            <Center flexDir="column">
                                                <Box flexGrow="1" p="2">
                                                    <Box shadow="lg">
                                                        <SelectProductPositons name="positions" {...props} />
                                                    </Box>
                                                </Box>
                                                <Text color="red.400">{form.errors.positions}</Text>
                                            </Center>
                                        )
                                    }}
                                </Field>

                                <Box flexGrow="1" float="right" p="2">
                                    <EditContact name="Kontakt" record={props.record} {...props} />
                                    <EditAdress name="Lieferadresse" type="shippingAdress" record={props.record} {...props} />
                                </Box>
                            </Box>
                            <Form onSubmit={formik.handleSubmit}>
                                <Box borderTop="1px solid gray" mt="2" mb="2" display="flex">
                                    <Box flexGrow="1">
                                        <Flex flexDir="row" w="100%" minW="210px" p="2" alignItems="center" justifyContent="center">
                                            <Box p="2">
                                                <Field name="weight">
                                                    {({ field, form }) => (
                                                        <FormControl isInvalid={form.errors.weight && form.touched.weight}>
                                                            <FormLabel htmlFor="weight">Gewicht (kg)</FormLabel>
                                                            <Input {...field} id="weight" placeholder="Gewicht in kg" />
                                                            <FormErrorMessage minH="1em">{form.errors.weight}</FormErrorMessage>
                                                        </FormControl>
                                                    )}
                                                </Field>
                                            </Box>
                                            <Box>
                                                <Field name="shipmentDate">
                                                    {({ field, form }) => {
                                                        return (
                                                            <FormControl isInvalid={form.errors.shipmentDate && form.touched.shipmentDate}>
                                                                <FormLabel htmlFor="shipmentDate">Versanddatum</FormLabel>
                                                                <Input {...field} id="shipmentDate" placeholder="Versanddatum" type="date" />
                                                                <FormErrorMessage>{form.errors.shipmentDate}</FormErrorMessage>
                                                            </FormControl>
                                                        )
                                                    }}
                                                </Field>
                                            </Box>
                                            <Box p="2" pl="4">
                                                <Box fontSize="md" textAlign="start" mb="2" fontWeight="medium" marginInlineEnd="3" display="block">
                                                    Fulfiller:
                                                </Box>
                                                <Box fontSize="md" height="10" paddingInlineEnd="4">
                                                    {props.record.value.shipping.fulfiller ? props.record.value.shipping.fulfiller : 'pickup'}
                                                </Box>
                                            </Box>
                                        </Flex>
                                    </Box>
                                </Box>
                            </Form>
                            
                            <Box w="100%" borderTop="1px solid grey" mt="2">
                            <Box float="right" h="5">
                            
                            </Box>
                                <Button
                                    float="right"
                                    type="primary"
                                    variant="primary"
                                    mt="2"
                                    onClick={async () => {
                                        formik.handleSubmit()
                                    }}
                                >
                                    <>
                                    Fulfillment senden

                                    {loading ? <Spinner  ml="2" /> : ""}
                                    </>
                                </Button>
                            </Box>
                        </FormikProvider>
                    </ModalBody>
                </ModalContent>
            </Modal>
        </>
    )
}

export const FulfillmentHeader = (props) => {
    const status = props.record?.status

    let text,
        clz = undefined
    let icon = RiErrorWarningLine
    switch (status) {
        case 'canceled':
        case 'failed':
            clz = 'red.600'
            text = 'Bestellung storniert abgeschlossen'
            icon = MdCancel

            break
        case 'completed':
            clz = 'green.600'
            text = 'Bestellung abgeschlossen'
            icon = ImCheckboxChecked
            break
        case 'pending':
        case 'processing':
        case 'onHold':
        default:
            clz = 'orange.400'
            text = 'Nicht ausgeführt'
    }

    return (
        <Box display="flex" fontSize="3xl" alignItems="center" mb="2">
            <Icon mr="2" color={clz} as={icon} />
            <Text fontSize="2xl">{text}</Text>
        </Box>
    )
}
