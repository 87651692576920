import {  Grid } from "@material-ui/core";
import {
  Edit,
  SimpleForm,
  TextInput,
  DateInput,
  NumberInput,
  SelectInput,
  AutocompleteInput
} from "react-admin";

import { Variant } from '../../util/style'

export const OptionEdit = (props: any) => (
  <Edit {...props}>
    <SimpleForm variant={Variant}>
      <Grid
        container
        direction="row"
        justify="flex-start"
        alignItems="flex-start"
        spacing={1}
        style={{ width: "100%", gap: "4rem" }}
      >
        <Grid container style={{ width: "100%", gap: "4rem" }} >
          <TextInput source="name" />

        </Grid>
        <Grid container style={{ width: "100%", gap: "4rem" }} >
          <TextInput source="hint" />
          <TextInput source="description" multiline={true} />
        </Grid>
        <AutocompleteInput label="Art" source="type" choices={[
          { id: 'duration', name: 'Dauer' },
          { id: 'addon', name: 'Addon' },
        ]} variant={Variant} />
        <Grid container style={{ width: "100%", gap: "4rem" }} >
          <NumberInput
            source="price.amount"
            label="Preis"
          />
          <SelectInput
            source="price.currency"
            label="Währung"
            choices={[{ id: "€", name: "€" }]}
            defaultValue="€"
          />
          <NumberInput
            source="price.offer.amount"
            label="Angebots Preis"
          />
          <SelectInput
            source="price.offer.currency"
            label="Währung"
            choices={[{ id: "€", name: "€" }]}
            defaultValue="€"
          />
        </Grid>
        <Grid container style={{ width: "100%", gap: "4rem" }} >
          <DateInput label="Angebots Start" source="price.offer.tsStart" />
          <DateInput label="Angebots Ende" source="price.offer.tsEnd" />
        </Grid>
        <TextInput label="Dauer" source="additionalAttributes.duration" />
        <TextInput label="Alterantiv Text bei 0 Euro" source="additionalAttributes.priceText" />
        <DateInput label="Optional: Datum ab dem die Mietung berechnet wird" source="additionalAttributes.durationStart" />
        
      </Grid>
    </SimpleForm>
  </Edit>
);
