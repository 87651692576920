import { Button } from "@material-ui/core"

export default function PreviewButton(props){
    let dis = `https://rebricker.com/api/disablePreview?secret=${process.env.REACT_APP_PREVIEW_SECRET}&slug=/sets`
    let text ="Vorschau Abschalten"
    
    if(!props.disable){
        if(!props?.record?.permalink){
            return (<div></div>)
        }
        dis = `https://rebricker.com/api/enablePreview?secret=${process.env.REACT_APP_PREVIEW_SECRET}&slug=/product/${props.record.permalink}`
        text = "Vorschau"
    }
    return(
        <Button href={dis} target="_blank" style={{color:props.disable ? "white":"black"}}>{text}</Button>
    )
}