/* tslint:disable */
/* eslint-disable */
/**
 * reBricker Shop Api
 * Api für den Client auf Kundenseite
 *
 * The version of the OpenAPI document: 1.0.2
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import { Configuration } from './configuration';
import globalAxios, { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from './common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from './base';

/**
 * 
 * @export
 * @interface DateRange
 */
export interface DateRange {
    /**
     * 
     * @type {string}
     * @memberof DateRange
     */
    'tsStart'?: string;
    /**
     * 
     * @type {string}
     * @memberof DateRange
     */
    'tsEnd'?: string;
}
/**
 * 
 * @export
 * @interface FindProductByQuery200Response
 */
export interface FindProductByQuery200Response {
    /**
     * Array of items
     * @type {Array<object>}
     * @memberof FindProductByQuery200Response
     */
    'items'?: Array<object>;
    /**
     * 
     * @type {PageableMeta}
     * @memberof FindProductByQuery200Response
     */
    'meta'?: PageableMeta;
}
/**
 * 
 * @export
 * @interface FindProductByQuery200ResponseAllOf
 */
export interface FindProductByQuery200ResponseAllOf {
    /**
     * Array of Products
     * @type {Array<Listing>}
     * @memberof FindProductByQuery200ResponseAllOf
     */
    'items'?: Array<Listing>;
}
/**
 * 
 * @export
 * @interface Fullfillment
 */
export interface Fullfillment {
    /**
     * Ids der Positionen welche Fullfillt werden
     * @type {Array<string>}
     * @memberof Fullfillment
     */
    'positions': Array<string>;
    /**
     * id of the Fullfillment
     * @type {string}
     * @memberof Fullfillment
     */
    'id'?: string;
    /**
     * Status
     * @type {string}
     * @memberof Fullfillment
     */
    'status'?: FullfillmentStatusEnum;
    /**
     * 
     * @type {FullfillmentShipment}
     * @memberof Fullfillment
     */
    'shipment'?: FullfillmentShipment;
    /**
     * 
     * @type {FullfillmentReturnShipment}
     * @memberof Fullfillment
     */
    'returnShipment'?: FullfillmentReturnShipment;
}

export const FullfillmentStatusEnum = {
    Created: 'created',
    Shipped: 'shipped',
    Received: 'received',
    Returned: 'returned'
} as const;

export type FullfillmentStatusEnum = typeof FullfillmentStatusEnum[keyof typeof FullfillmentStatusEnum];

/**
 * 
 * @export
 * @interface FullfillmentReturnShipment
 */
export interface FullfillmentReturnShipment {
    /**
     * Dienstleiter mit dem Verschickt wird.
     * @type {string}
     * @memberof FullfillmentReturnShipment
     */
    'fullfiller'?: FullfillmentReturnShipmentFullfillerEnum;
    /**
     * Nummer des Trackings, wenn übergeben wird kein Label erstellt.
     * @type {string}
     * @memberof FullfillmentReturnShipment
     */
    'trackingnumber'?: string;
    /**
     * Label als PDF
     * @type {string}
     * @memberof FullfillmentReturnShipment
     */
    'label'?: string;
    /**
     * DMC Label als Bild
     * @type {string}
     * @memberof FullfillmentReturnShipment
     */
    'dmc'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof FullfillmentReturnShipment
     */
    'delivered'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof FullfillmentReturnShipment
     */
    'tsDelivered'?: string;
}

export const FullfillmentReturnShipmentFullfillerEnum = {
    Dhl: 'DHL',
    PickUp: 'PickUp'
} as const;

export type FullfillmentReturnShipmentFullfillerEnum = typeof FullfillmentReturnShipmentFullfillerEnum[keyof typeof FullfillmentReturnShipmentFullfillerEnum];

/**
 * 
 * @export
 * @interface FullfillmentShipment
 */
export interface FullfillmentShipment {
    /**
     * Dienstleiter mit dem Verschickt wird.
     * @type {string}
     * @memberof FullfillmentShipment
     */
    'fullfiller'?: FullfillmentShipmentFullfillerEnum;
    /**
     * Nummer des Trackings, wenn übergeben wird kein Label erstellt.
     * @type {string}
     * @memberof FullfillmentShipment
     */
    'trackingnumber'?: string;
    /**
     * Gewicht in kg
     * @type {number}
     * @memberof FullfillmentShipment
     */
    'weight'?: number;
    /**
     * Datum im format YYYY-MM-DD
     * @type {string}
     * @memberof FullfillmentShipment
     */
    'shipmentDate'?: string;
    /**
     * Label als PDF
     * @type {string}
     * @memberof FullfillmentShipment
     */
    'label'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof FullfillmentShipment
     */
    'delivered'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof FullfillmentShipment
     */
    'tsDelivered'?: string;
}

export const FullfillmentShipmentFullfillerEnum = {
    Dhl: 'DHL',
    PickUp: 'PickUp'
} as const;

export type FullfillmentShipmentFullfillerEnum = typeof FullfillmentShipmentFullfillerEnum[keyof typeof FullfillmentShipmentFullfillerEnum];

/**
 * 
 * @export
 * @interface GetFullfillment200Response
 */
export interface GetFullfillment200Response {
    /**
     * Array of Fullfillments
     * @type {Array<Fullfillment>}
     * @memberof GetFullfillment200Response
     */
    'items'?: Array<Fullfillment>;
}
/**
 * 
 * @export
 * @interface Health
 */
export interface Health {
    /**
     * Value object to express state of a component or subsystem.  * `UNKNOWN` - indicating that the component or subsystem is in an unknown state. * `UP` - indicating that the component or subsystem is functioning as expected * `DOWN` - indicating that the component or subsystem has suffered an unexpected failure * `OUT_OF_SERVICE` - ndicating that the component or subsystem has been taken out of service and should not be used. 
     * @type {string}
     * @memberof Health
     */
    'status': HealthStatusEnum;
}

export const HealthStatusEnum = {
    Unknown: 'UNKNOWN',
    Up: 'UP',
    Down: 'DOWN',
    OutOfService: 'OUT_OF_SERVICE'
} as const;

export type HealthStatusEnum = typeof HealthStatusEnum[keyof typeof HealthStatusEnum];

/**
 * 
 * @export
 * @interface Info
 */
export interface Info {
    /**
     * 
     * @type {number}
     * @memberof Info
     */
    'code': number;
    /**
     * 
     * @type {string}
     * @memberof Info
     */
    'message': string;
}
/**
 * Liefert das Produkt aufgelöst hertuner. Das bedeutet: <br> * Optionen sind bereits preislich aufgelöst 
 * @export
 * @interface Listing
 */
export interface Listing {
    /**
     * Eindeutige Id wie der Artikel bei uns heist z.B. 10071374 => 100 für Lego 71374 für die Set nummer
     * @type {string}
     * @memberof Listing
     */
    'sku': string;
    /**
     * 
     * @type {string}
     * @memberof Listing
     */
    'type'?: ListingTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof Listing
     */
    'publishStatus'?: ListingPublishStatusEnum;
    /**
     * 
     * @type {string}
     * @memberof Listing
     */
    'available'?: ListingAvailableEnum;
    /**
     * Manufacturer Part Number - ArtikelId des Herstellers
     * @type {string}
     * @memberof Listing
     */
    'mfrPartNumber'?: string;
    /**
     * ean
     * @type {string}
     * @memberof Listing
     */
    'ean'?: string;
    /**
     * Hersteller
     * @type {string}
     * @memberof Listing
     */
    'brand'?: string;
    /**
     * Name des Artikels
     * @type {string}
     * @memberof Listing
     */
    'name': string;
    /**
     * Link unter dem des Artikel erreichbar ist: http://rebricker.com/p/{permalink} <br> Wenn nicht gefüllt wird automatisch beim Anlegen einer erzeugt<br><br> Ein PermaLink sollte wie folgt aufgebaut sein: * Keine Underscores, verwende - anstelle. Suchmaschinen mögen das nicht * todo 
     * @type {string}
     * @memberof Listing
     */
    'permalink'?: string;
    /**
     * 
     * @type {ListingDescription}
     * @memberof Listing
     */
    'description'?: ListingDescription;
    /**
     * 
     * @type {ListingSeo}
     * @memberof Listing
     */
    'seo'?: ListingSeo;
    /**
     * Zusärtliche Attribute als Name => Wer verknüpfung
     * @type {{ [key: string]: string; }}
     * @memberof Listing
     */
    'additionalAttributes'?: { [key: string]: string; };
    /**
     * 
     * @type {ListingSetInfo}
     * @memberof Listing
     */
    'setInfo'?: ListingSetInfo;
    /**
     * Informationen zum Bricks. Nur gefüllt wenn Typ == \"brick\"
     * @type {string}
     * @memberof Listing
     */
    'brickInfo'?: string;
    /**
     * 
     * @type {Array<ListingOption>}
     * @memberof Listing
     */
    'options'?: Array<ListingOption>;
    /**
     * 
     * @type {ListingPrice}
     * @memberof Listing
     */
    'price'?: ListingPrice;
    /**
     * 
     * @type {ListingPrice}
     * @memberof Listing
     */
    'shipping'?: ListingPrice;
    /**
     * 
     * @type {Array<ListingImagesInner>}
     * @memberof Listing
     */
    'images'?: Array<ListingImagesInner>;
}

export const ListingTypeEnum = {
    Set: 'set',
    Brick: 'brick',
    Merchandising: 'merchandising'
} as const;

export type ListingTypeEnum = typeof ListingTypeEnum[keyof typeof ListingTypeEnum];
export const ListingPublishStatusEnum = {
    Published: 'published',
    Draft: 'draft',
    Incomplete: 'incomplete'
} as const;

export type ListingPublishStatusEnum = typeof ListingPublishStatusEnum[keyof typeof ListingPublishStatusEnum];
export const ListingAvailableEnum = {
    Immediately: 'immediately',
    Shortly: 'shortly',
    OutOfStock: 'outOfStock',
    Preview: 'preview'
} as const;

export type ListingAvailableEnum = typeof ListingAvailableEnum[keyof typeof ListingAvailableEnum];

/**
 * Produktbeschreibung in unterschiedlichen Varianten
 * @export
 * @interface ListingDescription
 */
export interface ListingDescription {
    /**
     * Die Html Seite welche in der ProduktPage angezeigt wird
     * @type {string}
     * @memberof ListingDescription
     */
    'productpage'?: string;
    /**
     * Kurze Beschreibung die z.B. in der Landing Page angezeigt wird
     * @type {string}
     * @memberof ListingDescription
     */
    'short'?: string;
    /**
     * Beschreibung für die Checkout Seite, wenn nicht vorhanden wird der Name verwendet
     * @type {string}
     * @memberof ListingDescription
     */
    'checkout'?: string;
}
/**
 * 
 * @export
 * @interface ListingImagesInner
 */
export interface ListingImagesInner {
    /**
     * 
     * @type {string}
     * @memberof ListingImagesInner
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof ListingImagesInner
     */
    'url'?: string;
}
/**
 * 
 * @export
 * @interface ListingOption
 */
export interface ListingOption {
    /**
     * 
     * @type {string}
     * @memberof ListingOption
     */
    'id': string;
    /**
     * Name der Option
     * @type {string}
     * @memberof ListingOption
     */
    'name'?: string;
    /**
     * Hinweis welchen auf der Option angezeigt werden kann
     * @type {string}
     * @memberof ListingOption
     */
    'hint'?: string;
    /**
     * Beschreibung als HTML
     * @type {string}
     * @memberof ListingOption
     */
    'description'?: string;
    /**
     * Typ der Option. Je nach Optionstyp können diese unterschiedlich dargestellt werden.
     * @type {string}
     * @memberof ListingOption
     */
    'type'?: ListingOptionTypeEnum;
    /**
     * Zusätzliche Attribute als Name => Wert Verknüpfung
     * @type {{ [key: string]: string; }}
     * @memberof ListingOption
     */
    'additionalAttributes'?: { [key: string]: string; };
    /**
     * 
     * @type {ListingPrice}
     * @memberof ListingOption
     */
    'price'?: ListingPrice;
    /**
     * Der Zustand der option:  * `unselected` - Option ist abgewählt und kann geändert werden * `selected` - Option ist angewählt und kann geändert werden * `required` - Option ist angewählt und kann nicht abgewählt werden * `disabled` - Option ist abgewählt und kann nicht angewählt werden 
     * @type {string}
     * @memberof ListingOption
     */
    'state'?: ListingOptionStateEnum;
}

export const ListingOptionTypeEnum = {
    Duration: 'duration',
    Addon: 'addon',
    Shipping: 'shipping'
} as const;

export type ListingOptionTypeEnum = typeof ListingOptionTypeEnum[keyof typeof ListingOptionTypeEnum];
export const ListingOptionStateEnum = {
    Unselected: 'unselected',
    Selected: 'selected',
    Required: 'required',
    Disabled: 'disabled'
} as const;

export type ListingOptionStateEnum = typeof ListingOptionStateEnum[keyof typeof ListingOptionStateEnum];

/**
 * 
 * @export
 * @interface ListingPrice
 */
export interface ListingPrice {
    /**
     * 
     * @type {ListingPriceCurrent}
     * @memberof ListingPrice
     */
    'current'?: ListingPriceCurrent;
    /**
     * 
     * @type {ListingPriceRRP}
     * @memberof ListingPrice
     */
    'RRP'?: ListingPriceRRP;
}
/**
 * 
 * @export
 * @interface ListingPriceCurrent
 */
export interface ListingPriceCurrent {
    /**
     * 
     * @type {number}
     * @memberof ListingPriceCurrent
     */
    'amount'?: number;
    /**
     * 
     * @type {number}
     * @memberof ListingPriceCurrent
     */
    'tax'?: number;
    /**
     * 
     * @type {string}
     * @memberof ListingPriceCurrent
     */
    'currency'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof ListingPriceCurrent
     */
    'offer'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof ListingPriceCurrent
     */
    'validUntil'?: string;
}
/**
 * 
 * @export
 * @interface ListingPriceRRP
 */
export interface ListingPriceRRP {
    /**
     * 
     * @type {number}
     * @memberof ListingPriceRRP
     */
    'amount'?: number;
    /**
     * 
     * @type {number}
     * @memberof ListingPriceRRP
     */
    'tax'?: number;
    /**
     * 
     * @type {string}
     * @memberof ListingPriceRRP
     */
    'currency'?: string;
}
/**
 * Informationen welche für die Suchmaschineen benötigt werden
 * @export
 * @interface ListingSeo
 */
export interface ListingSeo {
    /**
     * Title der Seite für die Suchmaschine
     * @type {string}
     * @memberof ListingSeo
     */
    'pageTitle'?: string;
    /**
     * Kurze Beschreibung für die Suchmaschine
     * @type {string}
     * @memberof ListingSeo
     */
    'description'?: string;
}
/**
 * Informationen zum set. Nur gefüllt wenn Typ == \"set\"
 * @export
 * @interface ListingSetInfo
 */
export interface ListingSetInfo {
    /**
     * Handelt es sich um ein Moc oder Regulären Artikel?
     * @type {boolean}
     * @memberof ListingSetInfo
     */
    'moc'?: boolean;
    /**
     * Anzahl der Steine oder undefined
     * @type {number}
     * @memberof ListingSetInfo
     */
    'partcount'?: number;
    /**
     * Das Jahr in dem das Set veröffentlicht wurde
     * @type {number}
     * @memberof ListingSetInfo
     */
    'releaseYear'?: number;
    /**
     * Datum an dem das Set veröffentlicht wurde
     * @type {string}
     * @memberof ListingSetInfo
     */
    'releaseDate'?: string;
    /**
     * Anzahl an Stunden bis das Set aufgebaut ist oder undefined
     * @type {number}
     * @memberof ListingSetInfo
     */
    'buildDurationInHours'?: number;
    /**
     * Der Bauspaß als Zahl zwischen 1 und 6.  1) Wenig Bauspaß 6) Viel Abwechslung und hoher Bauspaß 
     * @type {number}
     * @memberof ListingSetInfo
     */
    'satisfaction'?: number;
    /**
     * Die Komplexität als Zahl zwischen 1 und 6.  1) Simple, auch für Kinder 6) Hohe Erfahrung nötig - Kann zur Verzweiflung führen 
     * @type {number}
     * @memberof ListingSetInfo
     */
    'complexity'?: number;
    /**
     * 
     * @type {Array<ListingSetInfoInstructionInner>}
     * @memberof ListingSetInfo
     */
    'instruction'?: Array<ListingSetInfoInstructionInner>;
    /**
     * 
     * @type {ListingSetInfoPriceInfo}
     * @memberof ListingSetInfo
     */
    'priceInfo'?: ListingSetInfoPriceInfo;
}
/**
 * 
 * @export
 * @interface ListingSetInfoInstructionInner
 */
export interface ListingSetInfoInstructionInner {
    /**
     * Der Name der Bauanleitung
     * @type {string}
     * @memberof ListingSetInfoInstructionInner
     */
    'name'?: string;
    /**
     * Die Url zur Bauanleitung
     * @type {string}
     * @memberof ListingSetInfoInstructionInner
     */
    'url'?: string;
}
/**
 * 
 * @export
 * @interface ListingSetInfoPriceInfo
 */
export interface ListingSetInfoPriceInfo {
    /**
     * 
     * @type {ListingPrice}
     * @memberof ListingSetInfoPriceInfo
     */
    'RRP'?: ListingPrice;
}
/**
 * 
 * @export
 * @interface Option
 */
export interface Option {
    /**
     * 
     * @type {string}
     * @memberof Option
     */
    '_id'?: string;
    /**
     * Name der Option
     * @type {string}
     * @memberof Option
     */
    'name': string;
    /**
     * Hinweis welchen auf der Option angezeigt werden kann
     * @type {string}
     * @memberof Option
     */
    'hint'?: string;
    /**
     * Beschreibung als HTML
     * @type {string}
     * @memberof Option
     */
    'description'?: string;
    /**
     * Typ der Option. Je nach Optionstyp können diese unterschiedlich dargestellt werden.
     * @type {string}
     * @memberof Option
     */
    'type': OptionTypeEnum;
    /**
     * Zusätzliche Attribute als Name => Wert Verknüpfung
     * @type {{ [key: string]: string; }}
     * @memberof Option
     */
    'additionalAttributes'?: { [key: string]: string; };
    /**
     * 
     * @type {PriceDefinition}
     * @memberof Option
     */
    'price'?: PriceDefinition;
}

export const OptionTypeEnum = {
    Duration: 'duration',
    Addon: 'addon'
} as const;

export type OptionTypeEnum = typeof OptionTypeEnum[keyof typeof OptionTypeEnum];

/**
 * 
 * @export
 * @interface Pageable
 */
export interface Pageable {
    /**
     * Array of items
     * @type {Array<object>}
     * @memberof Pageable
     */
    'items'?: Array<object>;
    /**
     * 
     * @type {PageableMeta}
     * @memberof Pageable
     */
    'meta'?: PageableMeta;
}
/**
 * 
 * @export
 * @interface PageableMeta
 */
export interface PageableMeta {
    /**
     * Total number of documents in collection that match a query
     * @type {number}
     * @memberof PageableMeta
     */
    'totalItems'?: number;
    /**
     * Limit that was used
     * @type {number}
     * @memberof PageableMeta
     */
    'limit'?: number;
    /**
     * Availability of prev page
     * @type {boolean}
     * @memberof PageableMeta
     */
    'hasPrevPage'?: boolean;
    /**
     * Availability of next page
     * @type {boolean}
     * @memberof PageableMeta
     */
    'hasNextPage'?: boolean;
    /**
     * Current page number
     * @type {number}
     * @memberof PageableMeta
     */
    'page'?: number;
    /**
     * Total number of pages.
     * @type {number}
     * @memberof PageableMeta
     */
    'totalPages'?: number;
    /**
     * Only if specified or default page/offset values were used
     * @type {number}
     * @memberof PageableMeta
     */
    'offset'?: number;
    /**
     * Previous page number if available or NULL
     * @type {number}
     * @memberof PageableMeta
     */
    'prevPage'?: number | null;
    /**
     * Next page number if available or NULL
     * @type {number}
     * @memberof PageableMeta
     */
    'nextPage'?: number | null;
    /**
     * The starting index/serial/chronological number of first document in current page.
     * @type {number}
     * @memberof PageableMeta
     */
    'pagingCounter'?: number;
}
/**
 * 
 * @export
 * @interface Price
 */
export interface Price {
    /**
     * 
     * @type {number}
     * @memberof Price
     */
    'amount'?: number;
    /**
     * 
     * @type {number}
     * @memberof Price
     */
    'tax'?: number;
    /**
     * 
     * @type {string}
     * @memberof Price
     */
    'currency'?: string;
}
/**
 * 
 * @export
 * @interface PriceDefinition
 */
export interface PriceDefinition {
    /**
     * NettoPreis pro Stück
     * @type {number}
     * @memberof PriceDefinition
     */
    'amount'?: number;
    /**
     * Währung
     * @type {string}
     * @memberof PriceDefinition
     */
    'currency'?: string;
    /**
     * 
     * @type {PriceDefinitionOffer}
     * @memberof PriceDefinition
     */
    'offer'?: PriceDefinitionOffer;
    /**
     * Mengenrabatt. Wird hauptsächlich bei Einzelsteinen verwendet.\\n Sollte ein Angebot vorhanden sein so muss der Preis noch mit dem Faktor von Std. Preis zu Angebotspreis berechnet werden 
     * @type {Array<PriceDefinitionTierInner>}
     * @memberof PriceDefinition
     */
    'tier'?: Array<PriceDefinitionTierInner>;
}
/**
 * Ein mögliches Angebot welche eine höhere Wertigkeit hat. Es muss der tsStart und tsEnd für die gültigkeit geprüft werden
 * @export
 * @interface PriceDefinitionOffer
 */
export interface PriceDefinitionOffer {
    /**
     * NettoPreis pro Stück
     * @type {number}
     * @memberof PriceDefinitionOffer
     */
    'amount'?: number;
    /**
     * Währung
     * @type {string}
     * @memberof PriceDefinitionOffer
     */
    'currency'?: string;
    /**
     * Angebott nur gültig ab diesem Datum
     * @type {string}
     * @memberof PriceDefinitionOffer
     */
    'tsStart'?: string;
    /**
     * Angebott nur gültig bis zu diesem Datum
     * @type {string}
     * @memberof PriceDefinitionOffer
     */
    'tsEnd'?: string;
}
/**
 * 
 * @export
 * @interface PriceDefinitionTierInner
 */
export interface PriceDefinitionTierInner {
    /**
     * Anzahl ab welcher dieser Preis gilt
     * @type {number}
     * @memberof PriceDefinitionTierInner
     */
    'count'?: number;
    /**
     * Netto Preis der für die Menge pro Stück gilt.  Sollte ein Angebot vorhanden sein so muss der Preis noch mit dem Faktor von Std. Preis zu Angebotspreis berechnet werden
     * @type {number}
     * @memberof PriceDefinitionTierInner
     */
    'amount'?: number;
    /**
     * Währung
     * @type {string}
     * @memberof PriceDefinitionTierInner
     */
    'currency'?: string;
}

/**
 * DefaultApi - axios parameter creator
 * @export
 */
export const DefaultApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Health Check
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        health: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/health`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * DefaultApi - functional programming interface
 * @export
 */
export const DefaultApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = DefaultApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Health Check
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async health(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Health>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.health(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * DefaultApi - factory interface
 * @export
 */
export const DefaultApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = DefaultApiFp(configuration)
    return {
        /**
         * 
         * @summary Health Check
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        health(options?: any): AxiosPromise<Health> {
            return localVarFp.health(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * DefaultApi - object-oriented interface
 * @export
 * @class DefaultApi
 * @extends {BaseAPI}
 */
export class DefaultApi extends BaseAPI {
    /**
     * 
     * @summary Health Check
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public health(options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).health(options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * FullfillmentApi - axios parameter creator
 * @export
 */
export const FullfillmentApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Fullfillment für einen Order ermitteln
         * @param {string} id OrderId für welche die Fullfillments gefunden werden sollen
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getFullfillment: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getFullfillment', 'id', id)
            const localVarPath = `/order/{id}/fullfillment`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Liefert das Shipment Label fallsvorahnden
         * @param {string} id Fullfillment für welches das Label angefragt wird
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getShipmentLabel: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getShipmentLabel', 'id', id)
            const localVarPath = `/fullfillment/{id}/shipmentLabel`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Fullfillment für einen Orderausführen
         * @param {string} id OrderId
         * @param {Fullfillment} fullfillment Fullfillment
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postFullfillment: async (id: string, fullfillment: Fullfillment, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('postFullfillment', 'id', id)
            // verify required parameter 'fullfillment' is not null or undefined
            assertParamExists('postFullfillment', 'fullfillment', fullfillment)
            const localVarPath = `/order/{id}/fullfillment`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(fullfillment, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * FullfillmentApi - functional programming interface
 * @export
 */
export const FullfillmentApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = FullfillmentApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Fullfillment für einen Order ermitteln
         * @param {string} id OrderId für welche die Fullfillments gefunden werden sollen
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getFullfillment(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetFullfillment200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getFullfillment(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Liefert das Shipment Label fallsvorahnden
         * @param {string} id Fullfillment für welches das Label angefragt wird
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getShipmentLabel(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getShipmentLabel(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Fullfillment für einen Orderausführen
         * @param {string} id OrderId
         * @param {Fullfillment} fullfillment Fullfillment
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async postFullfillment(id: string, fullfillment: Fullfillment, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Fullfillment>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.postFullfillment(id, fullfillment, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * FullfillmentApi - factory interface
 * @export
 */
export const FullfillmentApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = FullfillmentApiFp(configuration)
    return {
        /**
         * 
         * @summary Fullfillment für einen Order ermitteln
         * @param {string} id OrderId für welche die Fullfillments gefunden werden sollen
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getFullfillment(id: string, options?: any): AxiosPromise<GetFullfillment200Response> {
            return localVarFp.getFullfillment(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Liefert das Shipment Label fallsvorahnden
         * @param {string} id Fullfillment für welches das Label angefragt wird
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getShipmentLabel(id: string, options?: any): AxiosPromise<any> {
            return localVarFp.getShipmentLabel(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Fullfillment für einen Orderausführen
         * @param {string} id OrderId
         * @param {Fullfillment} fullfillment Fullfillment
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postFullfillment(id: string, fullfillment: Fullfillment, options?: any): AxiosPromise<Fullfillment> {
            return localVarFp.postFullfillment(id, fullfillment, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * FullfillmentApi - object-oriented interface
 * @export
 * @class FullfillmentApi
 * @extends {BaseAPI}
 */
export class FullfillmentApi extends BaseAPI {
    /**
     * 
     * @summary Fullfillment für einen Order ermitteln
     * @param {string} id OrderId für welche die Fullfillments gefunden werden sollen
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FullfillmentApi
     */
    public getFullfillment(id: string, options?: AxiosRequestConfig) {
        return FullfillmentApiFp(this.configuration).getFullfillment(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Liefert das Shipment Label fallsvorahnden
     * @param {string} id Fullfillment für welches das Label angefragt wird
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FullfillmentApi
     */
    public getShipmentLabel(id: string, options?: AxiosRequestConfig) {
        return FullfillmentApiFp(this.configuration).getShipmentLabel(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Fullfillment für einen Orderausführen
     * @param {string} id OrderId
     * @param {Fullfillment} fullfillment Fullfillment
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FullfillmentApi
     */
    public postFullfillment(id: string, fullfillment: Fullfillment, options?: AxiosRequestConfig) {
        return FullfillmentApiFp(this.configuration).postFullfillment(id, fullfillment, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * ListingApi - axios parameter creator
 * @export
 */
export const ListingApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Suche Produkte anhand von Query
         * @param {Array<string>} [tags] Es wird geprüft das min. ein übergebener Tag enthalten ist.
         * @param {Array<string>} [publishStatus] Publish Status
         * @param {Array<string>} [permalinks] Es wird geprüft das min. ein übergebener Permalink enthalten ist.
         * @param {'admin' | 'shop' | 'id'} [style] Unterschiedliche Rückgabe Varianten der Produkte 
         * @param {number} [limit] Limit wie viele Einträge geliefert werden sollen: -1 liefert alle Einträge
         * @param {number} [page] Aktuelle Page
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findProductByQuery: async (tags?: Array<string>, publishStatus?: Array<string>, permalinks?: Array<string>, style?: 'admin' | 'shop' | 'id', limit?: number, page?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/listing`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (tags) {
                localVarQueryParameter['tags'] = tags.join(COLLECTION_FORMATS.csv);
            }

            if (publishStatus) {
                localVarQueryParameter['publishStatus'] = publishStatus.join(COLLECTION_FORMATS.csv);
            }

            if (permalinks) {
                localVarQueryParameter['permalinks'] = permalinks.join(COLLECTION_FORMATS.csv);
            }

            if (style !== undefined) {
                localVarQueryParameter['style'] = style;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Liefert alle möglichen Zeiten für den übergebene Dauer
         * @param {string} sku SKU des Produktes
         * @param {number} duration Wie viele zusammenhängende Tage werden benötigt
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        list: async (sku: string, duration: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'sku' is not null or undefined
            assertParamExists('list', 'sku', sku)
            // verify required parameter 'duration' is not null or undefined
            assertParamExists('list', 'duration', duration)
            const localVarPath = `/listing/{sku}/available`
                .replace(`{${"sku"}}`, encodeURIComponent(String(sku)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (duration !== undefined) {
                localVarQueryParameter['duration'] = duration;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ListingApi - functional programming interface
 * @export
 */
export const ListingApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ListingApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Suche Produkte anhand von Query
         * @param {Array<string>} [tags] Es wird geprüft das min. ein übergebener Tag enthalten ist.
         * @param {Array<string>} [publishStatus] Publish Status
         * @param {Array<string>} [permalinks] Es wird geprüft das min. ein übergebener Permalink enthalten ist.
         * @param {'admin' | 'shop' | 'id'} [style] Unterschiedliche Rückgabe Varianten der Produkte 
         * @param {number} [limit] Limit wie viele Einträge geliefert werden sollen: -1 liefert alle Einträge
         * @param {number} [page] Aktuelle Page
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async findProductByQuery(tags?: Array<string>, publishStatus?: Array<string>, permalinks?: Array<string>, style?: 'admin' | 'shop' | 'id', limit?: number, page?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<FindProductByQuery200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.findProductByQuery(tags, publishStatus, permalinks, style, limit, page, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Liefert alle möglichen Zeiten für den übergebene Dauer
         * @param {string} sku SKU des Produktes
         * @param {number} duration Wie viele zusammenhängende Tage werden benötigt
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async list(sku: string, duration: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<DateRange>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.list(sku, duration, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * ListingApi - factory interface
 * @export
 */
export const ListingApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ListingApiFp(configuration)
    return {
        /**
         * 
         * @summary Suche Produkte anhand von Query
         * @param {Array<string>} [tags] Es wird geprüft das min. ein übergebener Tag enthalten ist.
         * @param {Array<string>} [publishStatus] Publish Status
         * @param {Array<string>} [permalinks] Es wird geprüft das min. ein übergebener Permalink enthalten ist.
         * @param {'admin' | 'shop' | 'id'} [style] Unterschiedliche Rückgabe Varianten der Produkte 
         * @param {number} [limit] Limit wie viele Einträge geliefert werden sollen: -1 liefert alle Einträge
         * @param {number} [page] Aktuelle Page
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findProductByQuery(tags?: Array<string>, publishStatus?: Array<string>, permalinks?: Array<string>, style?: 'admin' | 'shop' | 'id', limit?: number, page?: number, options?: any): AxiosPromise<FindProductByQuery200Response> {
            return localVarFp.findProductByQuery(tags, publishStatus, permalinks, style, limit, page, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Liefert alle möglichen Zeiten für den übergebene Dauer
         * @param {string} sku SKU des Produktes
         * @param {number} duration Wie viele zusammenhängende Tage werden benötigt
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        list(sku: string, duration: number, options?: any): AxiosPromise<Array<DateRange>> {
            return localVarFp.list(sku, duration, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ListingApi - object-oriented interface
 * @export
 * @class ListingApi
 * @extends {BaseAPI}
 */
export class ListingApi extends BaseAPI {
    /**
     * 
     * @summary Suche Produkte anhand von Query
     * @param {Array<string>} [tags] Es wird geprüft das min. ein übergebener Tag enthalten ist.
     * @param {Array<string>} [publishStatus] Publish Status
     * @param {Array<string>} [permalinks] Es wird geprüft das min. ein übergebener Permalink enthalten ist.
     * @param {'admin' | 'shop' | 'id'} [style] Unterschiedliche Rückgabe Varianten der Produkte 
     * @param {number} [limit] Limit wie viele Einträge geliefert werden sollen: -1 liefert alle Einträge
     * @param {number} [page] Aktuelle Page
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ListingApi
     */
    public findProductByQuery(tags?: Array<string>, publishStatus?: Array<string>, permalinks?: Array<string>, style?: 'admin' | 'shop' | 'id', limit?: number, page?: number, options?: AxiosRequestConfig) {
        return ListingApiFp(this.configuration).findProductByQuery(tags, publishStatus, permalinks, style, limit, page, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Liefert alle möglichen Zeiten für den übergebene Dauer
     * @param {string} sku SKU des Produktes
     * @param {number} duration Wie viele zusammenhängende Tage werden benötigt
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ListingApi
     */
    public list(sku: string, duration: number, options?: AxiosRequestConfig) {
        return ListingApiFp(this.configuration).list(sku, duration, options).then((request) => request(this.axios, this.basePath));
    }
}


