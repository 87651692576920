import * as React from 'react';
import { forwardRef } from 'react';
import MenuItem from '@material-ui/core/MenuItem';
import ExitIcon from '@material-ui/icons/PowerSettingsNew';
import { useAuth } from 'oidc-react';

const LogoutButton = forwardRef((props, ref) => {
	let auth = useAuth()
    const handleClick = async () => {
		await auth.signOutRedirect()
	}
    return (
        <MenuItem
            onClick={handleClick}
			//@ts-ignore
            ref={ref}
        >
            <ExitIcon /> Logout
        </MenuItem>
    );
});

export default LogoutButton;