import { Datagrid, List, NumberField, TextField } from "react-admin";



export const DiscountruleList = props => (
    <List {...props}>
        <Datagrid rowClick="edit">
            <TextField source="id" />
            <TextField source="customer.type" label="Erlaubte Kunden" />
            <TextField source="product.type" label="Erlaubte Produkte"/>
            <NumberField source="value.amount" label="Wert"/>
            <TextField source="value.type" label="Typ" />
            <NumberField source="usage.limit" label="Anzahl Benutzungen"/>
            <TextField source="title" label="Titel" />
            
        </Datagrid>
    </List>
);