import {
	Edit,
	SimpleForm,
	TextInput,
	DateInput,
	ArrayInput,
	SimpleFormIterator,
	NumberInput,
	ReferenceInput,
	SelectInput,
    AutocompleteInput 
  } from "react-admin";
  
export const InventoryEdit = props => (
    <Edit {...props}>
        <SimpleForm>
            <TextInput source="id" />
            <ReferenceInput label="Produkt" source="product" reference="product">
                <SelectInput optionText="name" />
            </ReferenceInput>
            <NumberInput label="Anzahl" source="count" />
            <AutocompleteInput label="Art" source="type" choices={[
                { id: 'rentable', name: 'Vermietung' },
                { id: 'sellable', name: 'Verkauf' },
            ]} />
             <AutocompleteInput label="Zustand" source="condition" choices={[
                { id: 'used', name: 'Gebraucht' },
                { id: 'new', name: 'Neu' },
                { id: 'unordered', name: 'Noch nicht bestellt' },
            ]} />
            <AutocompleteInput label="Vollständigkeit" source="completeness" choices={[
                { id: 'complete', name: 'Komplett' },
                { id: 'sealed', name: 'Versiegelt' },
                { id: 'incomplete', name: 'Unvollständig' },
            ]} />
             <AutocompleteInput label="Status" source="status" choices={[
                { id: 'disabled', name: 'Deaktiviert' },
                { id: 'available', name: 'Verfügbar' },
                { id: 'toOrder', name: 'Muss Bestellt werden' },
                { id: 'ordered', name: 'Bestellt' },
                { id: 'rented', name: 'Vermietet' },
               
                { id: 'destroyed', name: 'Zerstört' },
                { id: 'sold', name: 'Verkauft' },
            ]} />
            
           
            <ArrayInput source="available" label="Verfügbar">
                <SimpleFormIterator>
                    <DateInput label="Start" source="tsStart" />
                    <DateInput  label="Ende" source="tsEnd" />
                </SimpleFormIterator>
            </ArrayInput>
            <ArrayInput source="booked" label="Gebucht">
                <SimpleFormIterator>
                    <DateInput label="Start" source="tsStart" />
                    <DateInput  label="Ende" source="tsEnd" />
                    <AutocompleteInput label="Art" source="type" choices={[
                        { id: 'order', name: 'Auftrag' },
                        { id: 'blocked', name: 'Blockiert' },
                    ]} />
                    <TextInput label="Grund" source="reason" />
                    <ReferenceInput label="Auftrag" source="details.order" reference="order">
                        <SelectInput optionText="id" />
                    </ReferenceInput>
                </SimpleFormIterator>
            </ArrayInput>
            <ArrayInput source="reserved" label="Reserviert">
                <SimpleFormIterator>
                    <ReferenceInput label="Warenkorb" source="wallet" reference="wallet">
                        <SelectInput optionText="id" />
                    </ReferenceInput>
                    <DateInput label="Ablauf Datum" source="tsExpire" />
                    <DateInput label="Start" source="range.tsStart" />
                    <DateInput label="Ende" source="range.tsStart" />
                    <NumberInput label="Dauer" source="range.duration" />                    
                </SimpleFormIterator>
            </ArrayInput>
           
           
        </SimpleForm>
    </Edit>
);