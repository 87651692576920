import {
  List,
  Datagrid,
  TextField,
  ChipField,
  FunctionField,
  EditButton
} from "react-admin";
import PreviewButton from "../../components/PreviewButton/PreviewButton";

export const ProductList = (props) => (
  <List {...props} perPage={25}>
    <Datagrid rowClick="edit">
      <TextField label="SKU" source="sku" />

      <TextField label="Name" source="name" />
      <TextField label="Marke" source="brand" />
      <ChipField label="Art" source="type" />
      <ChipField label="Status" source="publish.status" />
      <FunctionField label="Tags" render={record => `${record.tags.length}`} />
      <FunctionField label="Optionen" render={record => `${record.options.length}`} />
      <PreviewButton {...props}/>
      <EditButton />
    </Datagrid>
  </List>
);
