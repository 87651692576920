
import {
	Edit,
	SimpleForm,
	TextInput,
	BooleanInput,
  } from "react-admin";
  
export const TagEdit = props => (
    <Edit {...props}>
        <SimpleForm>
            <BooleanInput source="supertag" />
            <TextInput source="parent" />
            <TextInput source="id" />
        </SimpleForm>
    </Edit>
);