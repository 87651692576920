import { Card, CardContent, Chip, Typography } from '@material-ui/core'
import {
    ArrayField,
    ChipField,
    Datagrid,
    DateField,
    Error,
    FilterList,
    FilterListItem,
    List,
    ListBase,
    Loading,
    Pagination,
    ReferenceField,
    ResourceContextProvider,
    SingleFieldList,
    TextField,
} from 'react-admin'
import { MdCategory } from 'react-icons/md'

export const Dashboard = (props) => {
    let datum = new Date(Date.now())
    return (
        <ResourceContextProvider value="timeline">
            <List
                syncWithLocation
                basePath="/timeline"
                perPage="25"
                
                filter={{ tsPlanned_gte: datum.toISOString(), category: 'shipment', status: 'open' }}
                sort={{ field: 'tsPlanned', order: 'ASC' }}
                
                {...props}
            >
                <Datagrid>
                    <ReferenceField label="Bestellung" source="parent.id" reference="order" sortable="false">
                        <TextField source="ordernr" />
                    </ReferenceField>
                    <ReferenceField label="Produkt" source="parent.id" reference="order" sortable="false">
                        <ArrayField source="positions">
                            <SingleFieldList>
                            <ReferenceField source="product" reference='product'>
                                <ChipField source="name" />
                            </ReferenceField>
                            </SingleFieldList>
                        </ArrayField>
                    </ReferenceField>
                    <ChipField source="category" sortable="false" />
                    <ChipField source="status" sortable="false" />
                    <DateField source="tsPlanned" options={{ year: 'numeric', month: '2-digit', day: '2-digit' }} label="Geplante Ausführung" />
                    <TextField source="message" label="Beschreibung" sortable="false" />
                </Datagrid>
            </List>
        </ResourceContextProvider>
    )
}

const TimelineFilterSidebar = () => {
    return (
        <Card sx={{ order: -1, mr: 2, mt: 9, width: 200 }}>
            <CardContent>
                <FilterList label="Kategorie" icon={<MdCategory />}>
                    <FilterListItem label="Versand" value={{ category: 'shipment' }} />
                    <FilterListItem label="Rücksendung" value={{ category: 'returnShipment' }} />
                    <FilterListItem label="E-Mail Versand" value={{ category: 'mail' }} />
                    <FilterListItem label="E-Mail Rücksendung" value={{ category: 'NOTIFICATION_SHIPMENT_RETURN' }} />
                </FilterList>
                <FilterList label="Status" icon={<MdCategory />}>
                    <FilterListItem label="Offen" value={{ status: 'open' }} />
                    <FilterListItem label="Abgeschlossen" value={{ status: 'completed' }} />
                    <FilterListItem label="Fehler" value={{ status: 'error' }} />
                    <FilterListItem label="Suspended" value={{ status: 'suspended' }} />
                </FilterList>
            </CardContent>
        </Card>
    )
}
