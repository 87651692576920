import {
	Edit,
	SimpleForm,
	TextInput,
	DateInput,
	ArrayInput,
	SimpleFormIterator,
	NumberInput,
	ReferenceInput,
	SelectInput,
  } from "react-admin";
  
export const WalletEdit = props => (
    <Edit {...props}>
        <SimpleForm>
            <TextInput source="tracking.customerUserAgent" />
            <NumberInput source="shipping.amount" />
            <NumberInput source="total.amount" />
            <TextInput source="coupons" />
            <TextInput source="status" />
            <ArrayInput source="positions"><SimpleFormIterator><TextInput source="quantity.unit" />
<NumberInput source="price.amount" />
<TextInput source="status" />
<ReferenceInput source="_id" reference="s"><SelectInput optionText="id" /></ReferenceInput>
<TextInput source="type" />
<TextInput source="product" />
<DateInput source="shipDate" />
<ArrayInput source="options"><SimpleFormIterator><NumberInput source="price.amount" />
<ReferenceInput source="_id" reference="s"><SelectInput optionText="id" /></ReferenceInput>
<TextInput source="option" /></SimpleFormIterator></ArrayInput>
<DateInput source="tsExpire" /></SimpleFormIterator></ArrayInput>
            <DateInput source="updatedAt" />
            <DateInput source="createdAt" />
            <TextInput source="id" />
        </SimpleForm>
    </Edit>
);