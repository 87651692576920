import { AutocompleteArrayInput, Edit, NumberInput, SelectInput, SimpleForm, TextInput, useQueryWithStore } from "react-admin";
import { useFormState } from 'react-final-form';
import StringPathToObjectVal from "../../util/StringPathToObjectVal";

const variantType = "standard";
/**
 * 
 * @param {
 *   name: string,
 *   id: string,
 *   label: string,
 *   source: string,
 *   sort_field: string,
 *   dependsOn: string z.B "product.type",
 *   disableWhen : string
 *   resource : string
 * } props 
 * @returns 
 */
const CustomAutocompleteArrayInput = (props) => {
    const { values } = useFormState();
    const { data, loading } = useQueryWithStore({
        type: 'getList',
        resource: props.resource,
        payload: { pagination: { perPage: 9999 }, sort: { field: props.sort_field } }
    });
    if (!data || loading) {
        return null
    }
    let choices = []
    data.forEach(el => {
        choices.push({ id: el[props.id ? props.id : "id"], name: el[props.name ? props.name : "name"] })
    })
    if (props.dependsOn && props.disableWhen) {
        let val = StringPathToObjectVal(values, props.dependsOn)
        if (val === props.disableWhen) {
            return null
        }
    }
    return (
        <AutocompleteArrayInput variant={variantType} source={props.source} label={props.label} choices={choices} />
    )
}

export const DiscountruleEdit = (props) => {

    return (
        <Edit {...props}>
            <SimpleForm>
                <TextInput variant={variantType} source="id" disabled={true} />
                <TextInput variant={variantType} source="title" label="Titel" />
                <SelectInput variant={variantType} source="customer.type" label="Erlaubte Kunden" choices={[{ id: "all", name: "Alle" }, { id: "list", name: "Liste" }]} />

                <CustomAutocompleteArrayInput resource="customer" name="userid" id="id" label="Kunden" source="customer.list" sort_field="userid" dependsOn="customer.type" disableWhen="all" />

                <SelectInput variant={variantType} source="product.type" label="Erlaubte Produkte" choices={[{ id: "all", name: "Alle" }, { id: "list", name: "Liste" }]} />
                <CustomAutocompleteArrayInput resource="product" name="name" id="id" label="Produkte" source="product.list" sort_field="name" dependsOn="product.type" disableWhen="all" />
                <NumberInput variant={variantType} source="value.amount" label="Wert" />
                <SelectInput variant={variantType} source="value.type" label="Typ" choices={[{ id: "fixed", name: "Fix" }, { id: "percentage", name: "Prozent" }]} />
                <NumberInput variant={variantType} source="usage.limit" label="Anzahl Benutzungen" />


            </SimpleForm>
        </Edit>
    )
};