
const useAuthProvider = (auth) => {

  return {
    login: async (params = {}) => {
      if(1+1 === 2){
        return Promise.resolve();
      }
      return Promise.reject()
    },
    logout: async () => {
      if(1+1 === 2){
        return Promise.resolve();
      }
      return Promise.reject()
    },
    checkError: (error) => {
      if(1+1 === 2){
        return Promise.resolve();
      }
      return Promise.reject()
    },
    checkAuth: async (params) => {
      if(1+1 === 2){
        return Promise.resolve();
      }
      return Promise.reject()
      
    },
    getPermissions: (params) => {
      if(1+1 === 2){
        return Promise.resolve();
      }
      return Promise.reject()
    },
  };
};
export default useAuthProvider;
