import {
  Create,
  SimpleForm,
  TextInput,
  NumberInput,
  ReferenceInput,
  SelectInput,
  AutocompleteInput
} from "react-admin";


import { Variant } from '../../util/style'

export const InventoryCreate = (props: any) => (
  <Create {...props}>
    <SimpleForm variant={Variant}>
      <TextInput source="id" />
      <ReferenceInput label="Produkt" source="product" reference="product">
                <SelectInput optionText="name" />
            </ReferenceInput>
            <NumberInput label="Anzahl" source="count" />
            <AutocompleteInput label="Art" source="type" choices={[
                { id: 'rentable', name: 'Vermietung' },
                { id: 'sellable', name: 'Verkauf' },
            ]} />
             <AutocompleteInput label="Zustand" source="condition" choices={[
                { id: 'used', name: 'Gebraucht' },
                { id: 'new', name: 'Neu' },
                { id: 'unordered', name: 'Noch nicht bestellt' },
            ]} />
            <AutocompleteInput label="Vollständigkeit" source="completeness" choices={[
                { id: 'complete', name: 'Komplett' },
                { id: 'sealed', name: 'Versiegelt' },
                { id: 'incomplete', name: 'Unvollständig' },
            ]} />
             <AutocompleteInput label="Status" source="status" choices={[
                { id: 'disabled', name: 'Deaktiviert' },
                { id: 'available', name: 'Verfügbar' },
                { id: 'toOrder', name: 'Muss Bestellt werden' },
                { id: 'ordered', name: 'Bestellt' },
                { id: 'rented', name: 'Vermietet' },
               
                { id: 'destroyed', name: 'Zerstört' },
                { id: 'sold', name: 'Verkauft' },
            ]} />
     
     
    </SimpleForm>
  </Create>
);
