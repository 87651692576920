import {
	List,
	Datagrid,
	TextField,
	BooleanField,
  } from "react-admin";

export const TagList = props => (
    <List {...props} perPage="25">
        <Datagrid rowClick="edit">
            <BooleanField source="supertag" />
            <TextField source="parent" />
            <TextField source="id" />
        </Datagrid>
    </List>
);