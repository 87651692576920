
import { makeStyles, Typography } from '@material-ui/core';
import { AppBar } from 'react-admin';

import PreviewButton from '../PreviewButton/PreviewButton';
const useStyles = makeStyles({
    title: {
        flex: 1,
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
        overflow: 'hidden',
    },
    spacer: {
        flex: 1,
    },
});
const CustomAppBar = props => {
    const classes = useStyles();
    return (
        <AppBar {...props}>
            <Typography
                variant="h6"
                color="inherit"
                className={classes.title}
                id="react-admin-title"
            />
            <PreviewButton disable={true}/>
        </AppBar>
    );
};

export default CustomAppBar;