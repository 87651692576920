import { Box, Table, Tbody, Td, Thead, Tr } from "@chakra-ui/react";
import { Link, useQueryWithStore } from "react-admin";

export default function DisplayProduct(props) {
  const { record } = props;
  const {productIds} = props;
  const { loaded, data: products } = useQueryWithStore(
    {
      type: "getMany",
      resource: "product",
      payload: {
        ids: productIds ?  productIds : [],
      },
    },
    {},
    (state) => {
      return productIds
        .map((productId) => state.admin.resources.product.data[productId])
        .filter((r) => typeof r !== "undefined")
        .reduce((prev, next) => {
          prev[next.id] = next;
          return prev;
        }, {});
    }
  );

  const { loaded: optionLoaded, data: options } = useQueryWithStore(
    {
      type: "getMany",
      resource: "option",
      payload: {
        ids: record
          ? record.positions
              .map((item) => item.options.map((option) => option.option))
              .flat()
          : [],
      },
    }
  );
  if (!loaded || !optionLoaded || !props.record || !productIds) return null;
  return (
    <Box border="1px solid lightgrey" mb="1">
      {productIds && 
      <Table variant="striped" colorScheme="gray">
        <Thead>
          <Tr>
            <Td>Produkt</Td>
            <Td>Optionen</Td>
          </Tr>
        </Thead>
        <Tbody>
          {productIds.map((item, index) => {
            console.log("item",item)
            return (
              products[item] && (
                <>
                  <Tr key={`${item}_${index}`}>
                    <Td>
                      <Link to={`/product/${item}`}>
                        {products[item].name}
                      </Link>
                    </Td>
                    <Td></Td>
                    <Td></Td>
                  </Tr>
                  {options.map(
                    (option, index) => (
                        <Tr key={`${option.id}_${index}`}>
                          <Td></Td>
                          <Td>
                            <Link to={`/option/${option.id}`}>
                              {option.name}
                            </Link>
                          </Td>
                          <Td></Td>
                        </Tr>
                      )
                  )}
                  <Tr key={`shipping_`}>
                          <Td></Td>
                          <Td>
                              {record.value.shipping.fulfiller.toUpperCase()}
                          </Td>
                          <Td></Td>
                  </Tr>
                </>
              )
            );
          })}
        </Tbody>
      </Table>}
    </Box>
  );
}
