import {
	List,
	Datagrid,
	TextField,
	DateField,
  } from "react-admin";
  
export const BookingList = props => (
    <List {...props} perPage={25}>
        <Datagrid rowClick="edit">
            <TextField source="type" />
            <TextField source="status" />
            <TextField source="product" />
            <TextField source="inventory" />
            <DateField source="tsStart" />
            <DateField source="tsEnd" />
            <DateField source="milestone.undefined" />
            <DateField source="updatedAt" />
            <DateField source="createdAt" />
            <TextField source="id" />
        </Datagrid>
    </List>
);