import { Edit, NumberInput, ReferenceArrayInput, ReferenceField, ReferenceInput, SelectArrayInput, SelectInput, SimpleForm, TextInput } from "react-admin";

const variantType = "standard";
export const DiscountcodeEdit = props => (
    <Edit {...props}>
        <SimpleForm>
            <TextInput variant={variantType} disabled={true} source="id" />
            <ReferenceArrayInput source="usage.customer" reference="customer">
                <SelectArrayInput variant={variantType} optionText="userid" label="Eingelöst von"/>
            </ReferenceArrayInput>
            <NumberInput variant={variantType} source="usage.count" label="Verwendungen" />
            <TextInput variant={variantType} source="code" />
            <ReferenceInput variant={variantType} label="Regel" source="rule" reference="discountrule">
                <SelectInput  optionText="title" />
            </ReferenceInput>

        </SimpleForm>
    </Edit>
);