import {
  Create,
  TextInput,
  BooleanInput,
  ArrayInput,
  SimpleFormIterator,
  NumberInput,
  ReferenceInput,
  SelectInput,
  TabbedForm,
  FormTab,
  required,
  ReferenceArrayInput,
  SelectArrayInput,
  minValue,
  maxValue,
} from "react-admin";
import { NoMultibleDefaultOptions } from "../../util/validator";
import { withStyles } from "@material-ui/core/styles";
import RichCodeInput from "../../components/CodeEditor/CodeEditor";
import { Box, Divider, Grid, Typography } from "@material-ui/core";

const ProductTitle = ({ record }) => {
  return <span>{record ? record.name : "Produkt"}</span>;
};

const variantType = "standard";

export const ProductCreate = withStyles({ card: { overflow: "initial" } })(
  (props) => (
    <Create {...props} title={<ProductTitle />}>
      <TabbedForm variant={variantType}>
        <FormTab label="Allgemein">
          <Grid
            container
            direction="row"
            justify="flex-start"
            alignItems="flex-start"
            spacing={1}
            style={{ width: "100%" }}
          >
            <Grid container xs={12} spacing={8}>
              <Grid item xs={3}>
                <TextInput
                  variant={variantType}
                  source="sku"
                  validate={required()}
                  label="SKU"
                  fullWidth
                />
              </Grid>
              <Grid item xs={3}>
                <TextInput
                  variant={variantType}
                  source="brand"
                  label="Marke"
                  fullWidth
                />
              </Grid>
            </Grid>

            <Grid container xs={12} spacing={8}>
              <Grid item xs={3}>
                <TextInput
                  variant={variantType}
                  source="name"
                  label="Name"
                  validate={required()}
                  fullWidth
                />
              </Grid>
              <Grid item xs={3}>
                <TextInput variant={variantType} source="permalink" fullWidth />
              </Grid>
            </Grid>
            <Grid container xs={12} spacing={8}>
              <Grid item xs={3}>
                <SelectInput
                  fullWidth
                  variant={variantType}
                  source="publish.status"
                  label="Status"
                  defaultValue="incomplete"
                  validate={required()}
                  choices={[
                    { id: "incomplete", name: "Incomplete" },
                    { id: "draft", name: "Draft" },
                    { id: "published", name: "Published" },
                  ]}
                />
              </Grid>
            </Grid>

            <Grid container xs={12} spacing={8}>
              <Grid item xs={12} style={{ padding: "48px 0px 0px 32px" }}>
                <Typography variant="h5">Maße</Typography>
                <Divider />
              </Grid>
              <Grid item xs={3}>
                <NumberInput
                  variant={variantType}
                  source="shippingDimensions.weight"
                  label="Gewicht in g"
                  validate={minValue(0)}
                  fullWidth
                />
              </Grid>
              <Grid item xs={3}>
                <NumberInput
                  variant={variantType}
                  source="shippingDimensions.height"
                  label="Höhe in cm"
                  fullWidth
                  validate={minValue(0)}
                />
              </Grid>
              <Grid item xs={3}>
                <NumberInput
                  variant={variantType}
                  source="shippingDimensions.length"
                  label="Länge in cm"
                  validate={minValue(0)}
                  fullWidth
                />
              </Grid>
              <Grid item xs={3}>
                <NumberInput
                  variant={variantType}
                  source="shippingDimensions.width"
                  label="Breite in cm"
                  validate={minValue(0)}
                  fullWidth
                />
              </Grid>
            </Grid>
            <Grid container xs={12} spacing={8}>
              <Grid item xs={12} style={{ padding: "48px 0px 0px 32px" }}>
                <Typography variant="h5">Bilder</Typography>
                <Divider />
              </Grid>
              <Grid item xs={3}>
                <ArrayInput source="images" style={{ width: "80%" }}>
                  <SimpleFormIterator>
                    <TextInput
                      source="name"
                      variant={variantType}
                      label="Name"
                      fullWidth
                    />
                    <TextInput
                      source="url"
                      type="url"
                      variant={variantType}
                      label="URL"
                      fullWidth
                    />
                  </SimpleFormIterator>
                </ArrayInput>
              </Grid>
            </Grid>
            <Grid container xs={12} spacing={8}>
              <Grid item xs={12} style={{ padding: "48px 0px 0px 32px" }}>
                <Typography variant="h5">Tags</Typography>
                <Divider />
              </Grid>
              <Grid item xs={3}>
                <ReferenceArrayInput source="tags" reference="tag" label="">
                  <SelectArrayInput optionText="id" variant={variantType} />
                </ReferenceArrayInput>
              </Grid>
            </Grid>
          </Grid>
        </FormTab>

        <FormTab label="Set-Info">
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
            }}
            style={{ width: "85%" }}
          >
            <Box style={{ display: "block" }}>
              <BooleanInput source="setInfo.moc" label="MOC" />
              <NumberInput
                variant={variantType}
                label="Teileanzahl"
                source="setInfo.partcount"
                validate={[minValue(0)]}
                style={{ display: "block" }}
              />
              <NumberInput
                variant={variantType}
                label="Erscheinungsjahr"
                source="setInfo.releaseYear"
                validate={[minValue(1940)]}
                style={{ display: "block" }}
              />
              <NumberInput
                variant={variantType}
                label="Bauzeit"
                source="setInfo.buildDurationInHours"
                validate={minValue(0)}
                style={{ display: "block" }}
              />
              <NumberInput
                variant={variantType}
                label="Bauspaß"
                source="setInfo.satisfaction"
                validate={[minValue(0), maxValue(6)]}
                style={{ display: "block" }}
              />
              <NumberInput
                variant={variantType}
                label="Schwierigkeit"
                source="setInfo.complexity"
                validate={[minValue(0), maxValue(6)]}
                style={{ display: "block" }}
              />
            </Box>
            <ArrayInput
              source="setInfo.instruction"
              label="Anleitungen"
              style={{ width: "45%" }}
            >
              <SimpleFormIterator>
                <TextInput
                  source="name"
                  label="Name"
                  variant={variantType}
                  fullWidth
                />
                <TextInput
                  source="url"
                  type="url"
                  label="URL"
                  variant={variantType}
                  fullWidth
                />
              </SimpleFormIterator>
            </ArrayInput>
          </Box>
        </FormTab>

        <FormTab label="Preis">
          <Grid
            container
            direction="row"
            justify="flex-start"
            alignItems="flex-start"
            spacing={1}
            style={{ width: "100%" }}
          >
            <Grid container xs={12} spacing={1}>
              <Grid item>
                <NumberInput
                  variant={variantType}
                  source="price.amount"
                  label="Grundpreis"
                  defaultValue="0"
                  validate={[minValue(0),required()]}
                />

              </Grid>
              <Grid item>
                <SelectInput
                  variant={variantType}
                  source="price.currency"
                  label="Währung"
                  choices={[{ id: "€", name: "€" }]}
                  defaultValue="€"
                />
              </Grid>
            </Grid>
            <Grid container xs={12} spacing={1}>
              <Grid item>
                <NumberInput
                  variant={variantType}
                  source="setInfo.priceInfo.RRP.amount"
                  label="Regulärer Retail Preis"
                  validate={[minValue(0)]}
                />
              </Grid>
              <Grid item>
                <SelectInput
                  variant={variantType}
                  source="setInfo.priceInfo.RRP.currency"
                  label="Währung"
                  choices={[{ id: "€", name: "€" }]}
                  defaultValue="€"
                />
              </Grid>
            </Grid>

            <Grid container xs={12} spacing={1}>
              <Grid item>
                <NumberInput
                  variant={variantType}
                  source="setInfo.priceInfo.POV.amount"
                  label="Part Out Value"
                  validate={[minValue(0)]}
                />
              </Grid>
              <Grid item>
                <SelectInput
                  variant={variantType}
                  source="setInfo.priceInfo.POV.currency"
                  label="Währung"
                  choices={[{ id: "€", name: "€" }]}
                  defaultValue="€"
                />
              </Grid>
            </Grid>

            <Grid container xs={12} spacing={1}>
              <Grid item>
                <NumberInput
                  variant={variantType}
                  source="setInfo.priceInfo.replacementValue.amount"
                  label="Replacement Value"
                />
              </Grid>
              <Grid item xs={3}>
                <TextInput
                  variant={variantType}
                  style={{ width: "100%" }}
                  source="setInfo.priceInfo.replacementValue.url"
                  label="Link zum Shop"
                  type="url"
                />
              </Grid>
            </Grid>
            <Grid container xs={12} spacing={1}>
              <Grid item>
                <SelectInput
                  allowEmpty
                  variant={variantType}
                  source="taxCode"
                  label="Steuer-Typ"
                  choices={[
                    { id: "default", name: "default" },
                    { id: "reduced price", name: "reduced price" },
                    { id: "tax free", name: "tax free" },
                  ]}
                  defaultValue="default"
                />
              </Grid>
            </Grid>
          </Grid>
        </FormTab>
        <FormTab label="brickInfo">
          <TextInput source="brickInfo.colorId" label="Farb-ID" />
          <TextInput source="brickInfo.colorName" label="Farb-Name" />
          <NumberInput source="brickInfo.bulkAmount" label="Bulk Amount" />
          <SelectInput
            allowEmpty
            variant={variantType}
            source="brickInfo.type"
            label="Typ"
            choices={[
              { id: "MINIFIG", name: "MINIFIG" },
              { id: "PART", name: "PART" },
              { id: "SET", name: "SET" },
              { id: "BOOK", name: "BOOK" },
              { id: "GEAR", name: "GEAR" },
              { id: "CATALOG", name: "CATALOG" },
              { id: "INSTRUCTION", name: "INSTRUCTION" },
              { id: "UNSORTED_LOT", name: "UNSORTED_LOT" },
              { id: "ORIGINAL_BOX", name: "ORIGINAL_BOX" },
            ]}
          />
        </FormTab>
        <FormTab label="Optionen">
          <ArrayInput source="options" validate={NoMultibleDefaultOptions}>
            <SimpleFormIterator>
              <ReferenceInput source="option" reference="option" label="Option">
                <SelectInput optionText="name" />
              </ReferenceInput>
              <BooleanInput source="isDefault" label="Default" />
            </SimpleFormIterator>
          </ArrayInput>
        </FormTab>
        <FormTab label="Beschreibung">
          <RichCodeInput source="description.productpage" />
        </FormTab>
      </TabbedForm>
    </Create>
  )
);
