

export default function StringPathToObjectVal(value, path) {
    let splitted = path.split(".")
    let val = null
    splitted.forEach((el, index) => {
        if (index === 0) {
            val = value[el]
        } else {
            val = val[el]
        }
    })
    return val
}