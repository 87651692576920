import { Edit, Toolbar, FormWithRedirect, useQueryWithStore, useMutation } from 'react-admin'
import { Card, CardContent } from '@material-ui/core'

import EditAdress from '../../components/EditAdress/EditAdress'
import EditContact from '../../components/EditContact/EditContact'
import { FulfillmentHeader, FulfillmentDialog } from '../../components/Fulfillment/Fulfillment'

import DisplayProduct from '../../components/DisplayProduct/DisplayProduct'
import { Box, Button, Center, Icon, Text } from '@chakra-ui/react'
import DisplayPayment, { PaymentHeader } from '../../components/DisplayPayment/DisplayPayment'
import OrderChronik from '../../components/Chronik/OrderChronik'
import { useEffect, useState } from 'react'
import { FullfillmentApi, ListingApi } from '../../api/private'
import FullfillmentDisplay from '../../components/FullfillmentDisplay/FullfillmentDisplay'
import { GoPackage } from 'react-icons/go'
export const OrderEdit = (props) => {
    return (
        <Edit title={`Bestellung #${props.id}`} component="div" {...props}>
            <OrderForm />
        </Edit>
    )
}
const OrderForm = (props) => {
    const [fullfilled, setFullfilled] = useState(undefined)
    const [unfullfilled, setUnFullfilled] = useState(undefined)

    const fetch = async () => {
        let api = new FullfillmentApi({ basePath: process.env.REACT_APP_API_URL })
        let data = await api.getFullfillment(props.record.id)
        setFullfilled(data.data)
        let un = []
        props.record.positions?.forEach((element) => {
            let found = false
            data.data.forEach((itm) => {
                itm.positions?.forEach((pos) => {
                    if (pos === element._id) {
                        found = true
                    }
                })
            })
            if (!found) {
                un.push(element.product)
            }
        })
        setUnFullfilled(un)
    }
    useEffect(() => {
        fetch()
    }, [props.record.id])
    return (
        <FormWithRedirect
            {...props}
            render={(formProps) => (
                <form>
                    <Box display={{ md: 'block', lg: 'flex' }} flexDir={'column'}>
                        <Box display="flex" flex={2} pb="3" mb="4" border="1.1px solid lightgrey" bg="white">
                            <Box flex={2} pr="3" pl="3" pt="1" shadow="lg">
                                {unfullfilled && unfullfilled.length > 0 ? (
                                    <>
                                        <FulfillmentHeader {...props} />

                                        <DisplayProduct productIds={unfullfilled ? unfullfilled : []} record={formProps.record} />
                                        <Box display="flex" justifyContent="end" pt="1" pb="3">
                                                <FulfillmentDialog {...props} onSaveCallback={() => fetch()} />
                                        </Box>
                                    </>
                                ) : (
                                    <Center h="100%" opacity="0.5">
                                        Fullfillment abgeschlossen
                                    </Center>
                                )}
                            </Box>
                            <Box flex={1} ml={{ xs: 0, lg: '1em' }} mt={{ xs: '1em', lg: 0 }}>
                                <Box shadow="lg">
                                    <EditContact name="Kontaktinformationen" record={formProps.record} {...props} />
                                    <EditAdress name="Lieferadresse" type="shippingAdress" record={formProps.record} {...props} />
                                    <EditAdress name="Rechungsadresse" type="billingAdress" record={formProps.record} {...props} />
                                </Box>
                            </Box>
                        </Box>
                        <Box mb="4" pb="3" border="1.1px solid lightgrey" shadow="lg" bg="white">
                            <Box display="flex" fontSize="3xl" alignItems="center">
                                <Icon m="2" color="green.600" as={GoPackage} />
                                <Text fontSize="2xl">Fullfillments</Text>
                            </Box>
                            <Box p="16px">
                                {fullfilled?.map((el) => (
                                    <FullfillmentDisplay fullfillment={el} record={formProps.record} />
                                ))}
                            </Box>
                        </Box>
                        <Box mt="5" border="1.1px solid lightgrey" shadow="lg" bg="white">
                            <Box p="16px">
                                <PaymentHeader {...props} />
                                <DisplayPayment {...props} />
                            </Box>
                        </Box>
                        <Box mt="5" border="1.1px solid lightgrey" shadow="lg" bg="white">
                            <OrderChronik {...props} />
                        </Box>
                    </Box>
                    <Toolbar
                        record={formProps.record}
                        basePath={formProps.basePath}
                        undoable={true}
                        invalid={formProps.invalid}
                        handleSubmit={formProps.handleSubmit}
                        saving={formProps.saving}
                        resource="customers"
                    />
                </form>
            )}
        />
    )
}

