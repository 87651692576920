import {
	List,
	Datagrid,
	TextField,
	DateField,
	NumberField,
	SingleFieldList,
	ChipField,
	ArrayField,
  } from "react-admin";

export const WalletList = props => (
    <List {...props} perPage="25">
        <Datagrid rowClick="edit">
            <TextField source="tracking.customerUserAgent" label="User Agent"/>
            <NumberField source="shipping.amount" label="Versand" options={{ style: 'currency', currency: 'EUR' }} locales="de-DE"/>
            <NumberField source="total.amount" label="Rechnungsbetrag" options={{ style: 'currency', currency: 'EUR' }} locales="de-DE"/>
            <TextField source="coupons" />
            <TextField source="status" />
            <ArrayField source="positions"><SingleFieldList><ChipField source="quantity.unit" /></SingleFieldList></ArrayField>
            <DateField source="updatedAt" locales="de-DE" options={{ year: '2-digit', month: '2-digit', day: 'numeric', hour: "2-digit", minute:"2-digit" }}/>
            <DateField source="createdAt" locales="de-DE" options={{ year: '2-digit', month: '2-digit', day: 'numeric', hour: "2-digit", minute:"2-digit" }}/>
            <TextField source="id" />
            <TextField source="payment.transactionid" label="Transaktions ID"/>
        </Datagrid>
    </List>
);