import { Create, NumberInput, SelectInput, SimpleForm, TextInput,required } from "react-admin";
const variantType = "standard";

export const DiscountruleCreate = props => (
    <Create {...props}>
        <SimpleForm>
            <TextInput variant={variantType} source="title" label="Titel" validate={required()}/>
            <SelectInput variant={variantType} defaultValue="all" source="customer.type" label="Erlaubte Kunden" choices={[{id:"all",name:"Alle"},{id:"list",name:"Liste"}]} />
            <SelectInput variant={variantType} defaultValue="all" source="product.type" label="Erlaubte Produkte" choices={[{id:"all",name:"Alle"},{id:"list",name:"Liste"}]} />
            <NumberInput variant={variantType} source="value.amount" label="Wert" validate={required()}/>
            <SelectInput variant={variantType} source="value.type" label="Typ" choices={[{id:"fixed",name:"Fix"},{id:"percentage",name:"Prozent"}]} validate={required()} />
            <NumberInput variant={variantType} defaultValue={1}  source="usage.limit" label="Anzahl Benutzungen" validate={required()} />
            
            
        </SimpleForm>
    </Create>
);